import React from "react"
import { Link } from "react-router-dom"


const Tren = () => {
  
  return (
    <>
      <section style={{backgroundColor:'#f8f9fe', padding:'20px'}}>
          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left'}}>En tren</h3>
          <div className='container'>
            <div style={{margin:'0px 0px 0px 0px'}}>
            <h1 style={{margin:'0px 0px 0px 0px', color:'#000'}}>+ 1 transbordo en autobús Albacete-Cartagena desde Madrid</h1>


                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Las obras de llegada de la Alta Velocidad a la Región de Murcia han supuesto la introducción de un transbordo en los desplazamientos en tren. En la actualidad, el desplazamiento desde la ciudad de Cartagena se realizará en autobús hasta Albacete (3h y 50 minutos) desde donde se cogerá el AVE hasta la Madrid (1h y 32 min). 
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Más información de RENFE: <a style={{textDecoration:'underline'}} href='https://www.renfe.com/es/es' target="_blank">https://www.renfe.com/es/es</a>
                </p>
                
           
                
            </div>          
          </div>  
      </section>
    </>
  )
}

export default Tren
