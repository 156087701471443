import React, {useState} from 'react'
import PropTypes from "prop-types";
import { Link } from "react-router-dom"

import { AiFillStar  } from "react-icons/ai";
import { MdOutlineBedroomChild, MdOutlineBedroomParent } from "react-icons/md";
import { GiPositionMarker } from "react-icons/gi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Carousel } from 'antd';


import './Card.css'
function Card( {hotel, images, estrellas, descripcion, precio_habitacion_individual, info_extra_habitacion_individual, precio_habitacion_doble, info_extra_habitacion_doble, info_reserva, direccion, mapa, telefono,web, derechos_autor,email, text_habitacion_individual, text_habitacion_doble, mostrar_datos_habitacion}){

    return(
            <div className = 'Card_alojamiento'>
            <div className="details_alojamiento">
                <div className="carrusel_alojamiento" >
                <Carousel autoplay style={{width:'550px', height:'100%'}}>
                {images.map((value) => {
                return (
                    <div>
                    <img src={value.img} style={{width:'100%', height:'410px', borderRadius:'10px'}} alt=""/>
                    </div>
                )
              })}
                   
                   
               
                
            </Carousel>
                   
                </div>
                <div className="box_alojamiento">
                    <div className="row_alojamiento">
                        <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'2rem'}}>{hotel}</h2>
                        {[...Array(estrellas)].map((e, i) => <AiFillStar style={{ fontSize: '25px', color:'gold'}} />)}
                        
                    </div>
                  
                <p>{descripcion}</p>
                {mostrar_datos_habitacion ? (
                                                <div style={{ marginTop:'20px', display:'flex', flexDirection:'row', width:'60%',  justifyContent: 'center', alignItems: 'center', margin:'auto'}}>
                                                <div style={{width:'45%'}}>
                                                    <MdOutlineBedroomChild  style={{fontSize: '90px', color:'#2d2a2aab' , marginBottom:'0px' }}/>
                                                    <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab', marginTop:'-10px'}}>{text_habitacion_individual}</h2>

                                                    <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'1.5rem'}}>{precio_habitacion_individual}*</h2>
                                                    <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.7rem', color:'#2d2a2aab', marginTop:'-20px'}}>{info_extra_habitacion_individual}</h2>
                                                </div>

                                                <div style={{backgroundColor:'#3734362e', width:'1px', height:'150px',justifyContent: 'center',margin: 'auto', alignItems: 'center'}}></div>

                                                <div style={{width:'45%'}}>
                                                    <MdOutlineBedroomParent  style={{fontSize: '90px',color:'#2d2a2aab', marginBottom:'0px' }} />
                                                    <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab', marginTop:'-10px'}}>{text_habitacion_doble}</h2>

                                                    <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'1.5rem'}}>{precio_habitacion_doble}*</h2>
                                                    <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.7rem', color:'#2d2a2aab', marginTop:'-20px'}}>{info_extra_habitacion_doble}</h2>
                                                </div>
                                                </div>
                ):(<div style={{height:'150px'}}/>)
                
                }
              

                <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab', marginTop:'20px'}}>{info_reserva}</h2>

                <div style={{backgroundColor:'#3734362e', width:'80%', height:'1px',justifyContent: 'center',margin: 'auto', alignItems: 'center'}}></div>
               <div style={{marginTop:'20px'}}>
                <div className='info_adicional_alojamiento'>
                            <div style={{display:'flex', alignContent:'left', alignItems:'left', margin:'auto'}}>
                                <GiPositionMarker  style={{fontSize: '20px', color:'#2d2a2aab' }} />
                                <a className='text_alojamiento' style={{textAlign:'center', fontWeight:'700', fontSize:'0.7rem', marginTop:'5px'}}href={mapa} target="_blank" rel="noreferrer"> {direccion}</a>
                            </div>
                            
                            <div className='barra_separado_alojamiento' style={{backgroundColor:'#3734362e', width:'1px', height:'20px',justifyContent: 'center',marginRight:'5px', marginLeft:'5px', alignItems: 'center'}}></div>
                            <div style={{display:'flex', alignContent:'left', alignItems:'left', margin:'auto'}}>
                                <BsFillTelephoneFill  style={{fontSize: '15px', color:'#2d2a2aab'}} />
                                <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.7rem', color:'#2d2a2aab', marginTop:'5px'}}> {telefono}</h2>
                            </div>
                           
                            <div className='barra_separado_alojamiento' style={{backgroundColor:'#3734362e', width:'1px', height:'20px',justifyContent: 'center', marginRight:'5px', marginLeft:'5px', alignItems: 'center'}}></div>
                        
                            <div style={{display:'flex', alignContent:'left', alignItems:'left', margin:'auto'}}>
                                <FaExternalLinkAlt  style={{fontSize: '15px', color:'#2d2a2aab' }} />                      
                                <a className='text_alojamiento' style={{textAlign:'center', fontWeight:'700', fontSize:'0.7rem', marginTop:'5px'}}href={web} target="_blank" rel="noreferrer"> {web}</a>
                            </div>
                            
                </div>
               </div>
                
                
                </div>
               
              </div>

            </div>
    )

}
Card.propTypes = {
    plan: PropTypes.string.isRequired,
    hotel: PropTypes.string,
    images: PropTypes.string,
    estrellas: PropTypes.number,
    descripcion: PropTypes.string,
    precio_habitacion_individual: PropTypes.string,
    info_extra_habitacion_individual: PropTypes.string,
    precio_habitacion_doble: PropTypes.string,
    info_extra_habitacion_doble: PropTypes.string,
    info_reserva: PropTypes.string,
    direccion: PropTypes.string,
    mapa: PropTypes.string,
    telefono: PropTypes.string,
    web: PropTypes.string,
    derechos_autor: PropTypes.string,
    email: PropTypes.string,
    text_habitacion_individual:PropTypes.string,
    text_habitacion_doble:PropTypes.string,

    mostrar_datos_habitacion:PropTypes.bool

  };
export default Card