import React from "react"
import { useEffect,useState  } from "react";
import MurallaMar_page from './MurallaMar_page';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const Pages = () => {
  useEffect(() => window.scrollTo(0, 0));
   return (
    <>
      <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Sede del congreso</h3>
          </div>
      </div>
      </section>
      <MurallaMar_page />
    </>
  )
}

export default Pages
