import React from "react"
import { useEffect } from "react";
import { Link } from "react-router-dom"
import styles from "../VideoHeader/videoheader.module.css";
import Tooltip from '@mui/material/Tooltip';
import { HiMail } from "react-icons/hi";

const Presentacion = () => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <section className='Seccion_white'>
        <div className='container'>
            <div style={{margin:'0px 0px 20px 0px'}}>
          
            <img     style={{maxHeight: 250, minHeight:50, height:'30vw' }}  src='assets/logojia2023.png' alt='' />
            <h3 class={styles.h3_ajustable}>VII JORNADAS DE INGENIERÍA DEL AGUA</h3>
            <h2 class={styles.h2_ajustable}>Cartagena, 18 y 19 de octubre de 2023</h2>

                      <p className={styles.text2}>
                           Campus Muralla del Mar, Calle Dr. Fleming, s/n, Cartagena, España
                      </p>

                      <div style={{display:'flex', alignContent:'center',alignItems:'center', justifyContent: 'center', margin:'auto'}}>
                          <HiMail  style={{fontSize: '30px', color:'#2d2a2aab', marginTop:'2px', marginRight:'5px'}} />
                          <a style={{textDecoration:'underline', fontSize:'1.2rem', alignContent:'center', alignItems:'center'}} href='mailto:congreso.jia2023@upct.es'>congreso.jia2023@upct.es</a>              
                      </div>

                      <div className='SocailIcon' style={{
                                  marginTop:'40px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'}}>
                                    <div style={{display:'none'}}>
                                      <i className='fab fa-facebook-f facebook'></i>
                                      <i className='fab fa-instagram instagram'></i>
                                      <i className='fab fa-twitter twitter'></i>
                                      <i className='fab fa-youtube youtube'></i>
                                      <i className='fab fa-pinterest pinterest'></i>
                                    </div>
                     
                      </div>
                      
                      <div className={styles.botones_inscripcion_resumen}>
                          
                            <a href="https://www.verticesur.es/congresos/jia2023/inscription/add_new" target="_blank" rel="noopener noreferrer">
                              <button className={styles.primary_btn}>  INSCRIPCIÓN</button>
                            </a>
                          
                            <a href="https://easychair.org/conferences/?conf=viijia2023" target="_blank" rel="noopener noreferrer">
                              <button className={styles.primary_btn}> ENVÍO RESÚMENES</button>
                            </a>
                           
                              
                          
                        
                      </div>

            </div>
              <div style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}> 

                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  La escasez de recursos hídricos, la contaminación de las masas de agua y el elevado riesgo de inundación se ven fuertemente incrementados por el calentamiento global y los cambios generados en el sistema climático. Como solución a estas amenazas, la <b>Ingeniería del Agua</b> presenta opciones de adaptación para revertir los daños, aumentar la resiliencia y ofrecer oportunidades beneficiosas. 
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem', marginTop:'20px'}}>
                  <b>La Ingeniería del Agua</b> contribuye al cumplimiento de la Agenda 2030 de la ONU para el Desarrollo Sostenible de manera directa a través de al menos 11 de un total de 17 Objetivos de Desarrollo Sostenible llamados a transformar nuestro mundo y mejorar la vida de todos.
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem', marginTop:'20px'}}>
                  <b>La Ingeniería del Agua</b> engloba una combinación de opciones basadas en la naturaleza, la ingeniería, la tecnología y la digitalización, así como medidas sociales e institucionales que la dotan de un carácter interdisciplinar abarcando diversos sectores. Por todo esto, se considera importante la existencia de puntos de encuentro donde se facilite el intercambio de conocimiento, especialmente entre la Universidad, la Empresa y la Administración. Como respuesta a esta necesidad, se crearon en 2009 las Jornadas de Ingeniería del Agua (JIA).
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem', marginTop:'20px'}}>
                  Las Jornadas de Ingeniería del Agua (JIA) son unos encuentros bienales, de carácter científico-técnico, que reúnen a los mejores expertos, nacionales e internacionales, tanto de las universidades como de las administraciones y empresas, y que son promovidas, conjuntamente, por la Fundación para el Fomento de la Ingeniería del Agua (FFIA) y el Capítulo Español de la International Association for Hydro-Environment Engineering and Research (IAHR).
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem', marginTop:'20px'}}>
                  Después de seis ediciones presenciales y una edición híbrida presencial/online celebradas en Madrid -en el Centro de Estudios Hidrográficos (CEH-CEDEX), en Barcelona -Universitat Politécnica de Catalunya-, en Valencia -Universitat Politécnica de Valencia-, en Córdoba –Universidad de Córdoba-, en A Coruña –Universidade da Coruña-, en Toledo – Universidad de Castilla La Mancha-, la edición híbrida en Madrid – Universidad Politécnica de Madrid-, las séptimas JIA se celebrarán en Cartagena, Universidad Politécnica de Cartagena.
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem', marginTop:'20px'}}>
                  La organización de las VII JIA ha sido asumida por el Grupo de Ingeniería Hidráulica, Marítima y Medioambiental (Hidr@m) de la Universidad Politécnica de Cartagena. Además de los cuatro temas generales sobre los que habitualmente giran estas jornadas, el tema monográfico de esta edición es el de “La Resiliencia de las Infraestructuras Hidráulicas ante el Cambio Climático”.
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem', marginTop:'20px'}}>
                  <b>Bienvenidos a las VII Jornadas de Ingeniería del agua.</b>
                </p>

              
              
            </div>
        </div>
      </section>
    </>
  )
}

export default Presentacion
