import { fontWeight } from "@mui/system";
import React from "react"
import { useEffect } from "react";
import Card from './Card'
import Data from "./Data"

const Pages = () => {
  useEffect(() => window.scrollTo(0, 0));
  

   return (
    
    <>
       <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Alojamiento</h3>
            <h1>Recomendaciones de hoteles de la Ciudad de Cartagena</h1>

            <p style={{textAlign:'center', fontWeight:'700', fontSize:'1.3rem', color:'#2d2a2aab', marginTop:'10px'}}>Para otras opciones de habitación, contacte con los distintos complejos hoteleros</p>
            <div style={{margin:'-5px 0px 20px 0px'}}>
                    
          </div>
          </div>

        

          <div style={{
            alignItems: 'center',
            justifyContent: 'center',
            
          }}>
               <div className='card_grid_alojamiento'>
             
               {Data.map((value) => {
                return (
                    <Card 
                        hotel={value.hotel}
                        images={value.images}
                        estrellas={value.estrellas}
                        descripcion={value.descripcion}
                        precio_habitacion_individual={value.precio_habitacion_individual}
                        info_extra_habitacion_individual={value.info_extra_habitacion_individual}
                        precio_habitacion_doble={value.precio_habitacion_doble}
                        info_extra_habitacion_doble={value.info_extra_habitacion_doble}
                        info_reserva={value.info_reserva}
                        mapa={value.mapa}
                        direccion={value.direccion}
                        telefono={value.telefono}
                        web={value.web}
                        derechos_autor={value.derechos_autor}
                        email={value.email}
                        text_habitacion_individual={value.text_habitacion_individual}
                        text_habitacion_doble={value.text_habitacion_doble}
                        mostrar_datos_habitacion={value.mostrar_datos_habitacion}
                    />
                )
              })}
            </div>
          </div>
         

          <h4 style={{textAlign:'center', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab', marginTop:'20px'}}>
              *Los precios mostrados son a título orientativos y están sujetos a modificación por parte de los complejos hoteleros
            </h4>   
            <h4 style={{textAlign:'center', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab', marginTop:'20px'}}>
              **Todas las imágenes son propiedad de los respectivos complejos hoteleros
            </h4>   
          
        </div>      
        
      </section>
    </>
  )
}

export default Pages
