import React from "react"
import Tooltip from '@mui/material/Tooltip';
import styles from "./OrganizadorPromotor.module.css";

const OrganizadorPromotor = () => {

  return (
    <>
      <section className='Seccion_white'>
      <div className='container'>            
            <div class={styles.organizacion_promotor_organizador}>
                      
            <div class={styles.organizacion_promotor_organizador_alineado}>
                          <div style={{width:'100%'}}>
                          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left'}}>Promueven</h3>

                            <div style={{ margin:'0px',width:'100%', display:'flex', flexDirection:'row',  justifyContent: 'center', alignContent:'center'}}>
                              <div style={{width:'33%'}}>
                                <Tooltip title="The International Association for Hydro-Environment Engineering and Research"  > 
                                <a href="https://www.iahr.org/" target="_blank" rel="noreferrer">        
                                  <img class={styles.img_iahr}  src='assets/footer/promotores/IAHR.png' alt='IAHR' />       
                                </a>
                                </Tooltip>
                              </div>
                              <div style={{width:'33%'}}>
                                <Tooltip title="Fundación para el Fomento de la Ingeniería del Agua"  >  
                                <a href="https://www.afandaluzas.org/fundacion-para-el-fomento-de-la-ingenieria-del-agua/" target="_blank" rel="noreferrer">        
                                   <img  class={styles.img_afandaluzas}  src='assets/footer/promotores/FFIA.png' alt='' />
                                </a>
                                </Tooltip>
                              </div>   
                              <div style={{width:'33%'}}>
                              </div>                            
                            </div>
                          </div>

                          <div style={{width:'100%'}}>
                          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left'}}>Organizan</h3>
                                <Tooltip title="Universidad Politécnica de Cartagena"  >        
                                <a href="https://www.upct.es/" target="_blank" rel="noreferrer">        
                                  <img class={styles.img_upct} src='assets/footer/organizadores/upct-miembro-eut-azul.png' alt='' />
                                </a>  
                                </Tooltip>
                            <div style={{ margin:'0px',width:'100%', display:'flex', flexDirection:'row',  justifyContent: 'center', alignContent:'center'}}>
                          
                              <div style={{width:'40%'}}>
                                  <Tooltip title="Escuela Técnica Superior de Ingeniería de Caminos, Canales y Puertos y de Ingeniería de Minas"  >  
                                  <a href="https://caminosyminas.upct.es/" target="_blank" rel="noreferrer">        
                                    <img class={styles.img_caminos}  src='assets/footer/organizadores/EICM.jpg' alt='' />
                                  </a>  
                                  </Tooltip>
                                </div>  
                                <div style={{width:'40%'}}>
                                  <Tooltip title="Grupo de investigación Hidr@m"  > 
                                  <a href="https://www.upct.es/hidrom/" target="_blank" rel="noreferrer">        
                                    <img class={styles.img_hidrom}  src='assets/footer/organizadores/hidrom.jpg' alt='' />
                                  </a> 
                                  </Tooltip>
                                </div> 
                             
                            </div>
                          </div>
                          
                      </div>
                  
            </div>
           </div> 
      </section>
    </>
  )
}

export default OrganizadorPromotor
