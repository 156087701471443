const comite_organizador_local_vocales= [

  {
    nombre:'Juan José Alonso',
    centro:'Hidrogea'
  },
  {
    nombre:'Luís Altarejos García',
    centro:'UPCT'
  },
  {
    nombre:'Daniel Carreres Prieto',
    centro:'CUD'
  },
  {
    nombre:'Sarai Díaz García',
    centro:'UCLM'
  },

  {
    nombre:'Sebastián Guillén Ludeña',
    centro:'UPCT'
  },
  {
    nombre:'Francisco Javier Pérez De La Cruz',
    centro:'UPCT'
  },
  {
    nombre:'María del Mar Reventós Martínez',
    centro:'MCT'
  },
  {
    nombre:'Alfredo Salar de Diego',
    centro:'CICCP Demarcación Murcia'
  },
  {
    nombre:'José Sandoval Moreno',
    centro:'DGA CARM'
  },
  {
    nombre:'Antonio Vigueras Rodríguez',
    centro:'UPCT'
  },
  {
    nombre:'Ignacio Villanueva Jover',
    centro:'CHS'
  },

 

  ];
  export default comite_organizador_local_vocales