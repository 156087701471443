import React from "react"
import { useEffect } from "react";
import './estilos.css'



const Curso_WorkshopUDMT = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    <>
      <section className='Seccion_white_contenedor'>
            <div className='heading'>
              <h3>Curso sobre buenas prácticas para la monitorización de caudales y contaminación</h3>
              <h1>Herramienta de análisis y tratamiento de datos de monitorización del Ciclo integral del Agua y trabajos de hidráulica experimental</h1>
              <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>17 de octubre 2023</h1>

            </div>
      </section>
      <section >
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Profesores responsables del curso:</h1>

          <div className='container'>        
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      José Anta  (UDC) <a style={{textDecoration:'underline'}} href='mailto:jose.anta@udc.es'>jose.anta@udc.es</a>
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Juan Naves  (UDC) <a style={{textDecoration:'underline'}} href='mailto:juan.naves@udc.es'>juan.naves@udc.es</a>
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Manuel Regueiro-Picallo (UDC) <a style={{textDecoration:'underline'}} href='mailto:manuel.regueiro1@udc.es'>manuel.regueiro1@udc.es</a>
                      </li>
              </ul>
          
          </div> 
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Contexto:</h1>

          <div className='container'> 
            <p style={{textAlign:'justify', fontSize:'1rem'}}>
                El proyecto europeo Co-UDlabs 
                <a style={{textAlign:'center', fontWeight:'700', fontSize:'1rem'}} href={'https://co-udlabs.eu/'} target="_blank" rel="noreferrer"> www.co-udlabs.eu </a>
                 es una red de infraestructuras de investigación que pretende integrar las actividades de investigación e innovación en el ámbito de los sistemas de drenaje urbano y facilitar la colaboración entre investigadores y profesionales del sector del Ciclo Integral del Agua. Co-UDlabs está desarrollando la aplicación web gratuita Urban Drainage Metrology Toolbox (UDMT) que incluye varias aplicaciones basadas en algoritmos avanzados y automatizados e interfaces de usuario desarrolladas con Matlab que permiten:
                </p>       
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Calibración de sensores y análisis de correlación de señales en continuo (ej. Turbidez y SST)
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Corrección de datos brutos a partir de funciones de calibración de sensores
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Métodos de validación de datos
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Evaluación de la incertidumbre de las mediciones
                      </li>
              </ul>

          </div> 
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Contenido del curso:</h1> 
          <div className='container'> 
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  10:30 – 10:45. Recepción de asistentes
                  </p>         
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  10:45 – 12:00 Bloque 1
                  </p>
                      <ul className='ul_autor'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Presentación del proyecto Co-UDlabs
                          </li>
                      </ul>
                      <ul className='ul_autor'>
                        <li style={{textAlign: 'left', fontSize:'1rem'}}>
                          Fundamentos de la herramienta UDMT
                        </li>
                      </ul>
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  12:00 – 12:30. Pausa Café
                  </p>         
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  12:30 – 14:00. Bloque 2 
                  </p>
                      <ul className='ul_autor'>
                        <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Presentación de la herramienta UDMT, versiones web y local
                        </li>
                      </ul>
                      <ul className='ul_autor'>
                        <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Caso práctico UDMT. Análisis de datos reales procedentes de un contador con telelectura
                        </li>
                      </ul>
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                      14:00 – 15:00. Pausa comida
                  </p>         
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  15:00 – 16:30. Bloque 3 
                  </p>
                      <ul className='ul_autor'>
                        <li style={{textAlign: 'left', fontSize:'1rem'}}>
                          Caso práctico UDMT. Análisis de datos reales procedentes de una sonda de turbidez en continuo
                        </li>
                      </ul>
                      <ul className='ul_autor'>
                        <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Discusión de casos prácticos con asistentes
                        </li>
                      </ul>
              </div>
              
          </div>

           <div className='container'> 
            <p style={{textAlign:'justify', fontSize:'1rem'}}>
            Como información adicional, el manual de usuario de la herramienta UDMT (versión en inglés) está disponible en el siguiente enlace: 
                <a style={{textAlign:'center', fontWeight:'700', fontSize:'1rem'}} href={'https://doi.org/10.5281/zenodo.7876542'} target="_blank" rel="noreferrer"> https://doi.org/10.5281/zenodo.7876542 </a>
                </p>       
           

          </div>  

          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Organización:</h1> 
          <div className='container' style={{marginBottom:'20px'}}> 
          <div className="grid_img_cursos">
              <img src='/assets/cursos/logo_coudelabs.png' alt='' width='200px'/>                         
              <img src='/assets/cursos/financiacion_coudlabs.png' alt='' width='300px' />   
          </div>

          
            <p style={{textAlign:'justify', fontSize:'1rem', marginBottom:'20px'}}>
                  Building Collaborative Urban Drainage research lab communities <a style={{textAlign:'right', fontWeight:'700', fontSize:'1rem'}} href={'https://co-udlabs.eu/'} target="_blank" rel="noreferrer"> www.co-udlabs.eu </a>
                
            </p>

            <div className="grid_img_cursos">
              <img src='/assets/footer/colaboradores/RLHE.jpg' alt='' width='200px'/>                         
          </div>

          
            <p style={{textAlign:'justify', fontSize:'1rem', marginBottom:'20px'}}>
            Curso organizado por la Red de Laboratorios de Hidráulica de España (RLHE)
                
            </p>
          </div>

          
      

          
              
        
         
          
      </section>
      

    </>
  )
}

export default Curso_WorkshopUDMT
