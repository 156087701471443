import React from "react"
import { Link } from "react-router-dom"
import './estilos.css'


const Descuento = () => {
  
  return (
    <>
      <section style={{backgroundColor:'#f8f9fe', padding:'20px 0px 10px 0px'}}>
          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left', margin:'20px'}}>B)	Descuentos en la cuota de inscripción</h3>
          <h1 style={{margin:'20px 0px 20px 30px', color:'#1886e6'}}>Descuento en la cuota de inscripción de estudiantes de doctorado</h1>
          <div className='container'>
            <div style={{margin:'0px 0px 0px 0px'}}>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El congreso ofrece un descuento en la inscripción (50%) para los estudiantes de doctorado si cumplen los siguientes requisitos:
                </p>

                <ul className='ul_autor'>
                   <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Estar matriculado en un programa de doctorado y tener avanzada la realización de una tesis doctoral en el ámbito de la Ingeniería del Agua.
                   </li>
                </ul>
                <ul className='ul_autor'>
                   <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Proponer la presentación de una comunicación a JIA 2023 donde se recojan resultados ya alcanzados en el desarrollo de la tesis.
                   </li>
                </ul>
                <ul className='ul_autor'>
                   <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      El doctorando deberá ser el primer autor y quien presente la comunicación. El director o directores de la tesis doctoral deberán ser coautores.
                   </li>
                </ul>

                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  A la hora de realizar el envío del Resumen (antes del 14 de Abril de 2023), los estudiantes deben indicar esta circunstancia en el formulario de envío. La organización notificará a cada congresista un código de descuento que deben incluir a la hora de hacer la inscripción.
                </p>
              
            </div>          
          </div>      
        
        
      </section>
    </>
  )
}

export default Descuento
