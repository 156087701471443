import React from "react"
import { useEffect } from "react";
import './estilos.css'



const Curso_Tetis = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    <>
      <section className='Seccion_white_contenedor'>
            <div className='heading'>
              <h3>Curso de TETIS V9.1</h3>
              <h1>CURSO MODELANDO EL CICLO DEL AGUA CON TETIS V9.1</h1>
              <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>17 de octubre 2023</h1>

            </div>
      </section>
      <section >
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Profesores responsables del curso:</h1>

          <div className='container'>        
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Félix Francés García (UPV) <a style={{textDecoration:'underline'}} href='mailto:ffrances@upv.es'>ffrances@upv.es</a>
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Eduardo Albentosa Hernández (UPV) <a style={{textDecoration:'underline'}} href='mailto:ealbento@hma.upv.es'>ealbento@hma.upv.es</a>
                      </li>
              </ul>
              
          </div> 

          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Persona de contacto:</h1>

          <div className='container'>        
           
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Félix Francés García (UPV) <a style={{textDecoration:'underline'}} href='mailto:ffrances@upv.es'>ffrances@upv.es</a>
                      </li>
              </ul>
              
          </div> 
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Información general del curso:</h1> 
          <div className='container'> 
              <div style={{marginBottom:'20px'}}>       
                  
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El día 17 de octubre se celebrará en el Campus de Alfonso XIII de la Universidad Politécnica de Cartagena el curso  <b>Modelando el ciclo del agua con TETIS v9.1</b>, dirigido a profesionales, investigadores y estudiantes universitarios interesados en adquirir mayores habilidades en el manejo de un modelo hidrológico distribuido para simulación continua y de evento, tanto de agua como de sedimentos, a escala de cuenca. El curso será eminentemente práctico y tendrá una duración de un día completo (8 horas de clases más los descansos). Cada alumno deberá acudir con su ordenador portátil.
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Los objetivos concretos de aprendizaje son: conocer la conceptualización del modelo TETIS, aprender a manejar de forma avanzada el programa TETIS v9.1, conocer las implicaciones de la variabilidad espacial del ciclo hidrológico en su modelización, e implementar el modelo TETIS en un caso de estudio.
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El curso lo organiza el Grupo de Investigación de Modelación Hidrológica y Ambiental (GIMHA) del Instituto de Investigación de Ingeniería del Agua y Medio Ambiente (IIAMA) de la Universitat Politècnica de València, cuyos miembros cuentan con una amplia experiencia en el campo de la modelación de los diferentes procesos y relaciones que se dan en los sistemas hidrológicos. El profesorado estará formado por Félix Francés García, Eduardo Albentosa Hernández y personal de apoyo del GIMHA.
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El coste de matrícula en el curso es de 190€ (150€ para estudiantes), e incluye documentación, cafés y comida. El número máximo de plazas admitidas es de 25; si no se superase el número de 11 alumnos matriculados, la organización del curso se reserva el derecho de suspender su celebración. 
                  </p>
           
              </div>
              
          </div> 
            <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Contenido del curso:</h1> 
          <div className='container'> 
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  - Tema 1. Fudamentos teóricos del modelo TETIS
                  </p>
              </div>
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  - Tema 2. Implementación de modelo distribuido
                  </p>
              </div>
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  - Práctica 1. Introducción al programa TETIS v9 
                  </p>                 
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  - Práctica 2. Análisis de sensibilidad 
                  </p>                  
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  - Práctica 3. Calibración manual 
                  </p>                                
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                  - Práctica 4. Calibracíon automática 
                  </p>                 
              </div>

              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    - Práctica 5. Validación 
                  </p>                 
              </div>
              
          </div> 

          <div className='container' style={{marginBottom:'20px'}}> 
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                PARA MÁS INFORMACIÓN SOBRE EL CURSO, PULSE EN EL SIGUIENTE <a href="/assets/docs/Programación CURSO TETIS 2023-JIA.pdf" target="_blank" rel="noreferrer"><b>ENLACE DETALLADO</b></a>
                </p> 
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                SE PERMITE, EN ESTE CASO, LA INSCRIPCIÓN AL CURSO DE MANERA INDEPENDIENTE A LA INSCRIPCIÓN AL CONGRESO CONTACTANDO DE FORMA DIRECTA CON EL PROF. FÉLIX FRANCÉS <a style={{textDecoration:'underline'}} href='mailto:ffrances@upv.es'>ffrances@upv.es</a>
                </p>        
          </div> 
          
      </section>
      

    </>
  )
}

export default Curso_Tetis
