import React, {useState} from 'react'
import PropTypes from "prop-types";

import './Card.css'
function Card( {linea, linea2, letra,puntos, ods, foto_background}){
    return(
        <div className='Encabecado_Card_ODS'>
                    <h3 style={{color:'#1886e6', fontSize:'20px'}}>{letra} - {linea}</h3>
                    {linea2.length > 0 &&
                        <h3 style={{color:'#000', fontSize:'16px'}}>{linea2}</h3>
                    }
    
        <div className = 'Card_ODS'>
                <div className = 'upper-container_ODS' 
                    style={{
                        backgroundImage: `url(${foto_background})`, 
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize:'cover',
                        borderRadius: '10px 10px 0px 0px'
                    }}>
                
               
              

                    
                </div>
                <div className ='lower-container_ODS'>
             
                    
                    <div style={{height:'270px', width:'90%', justifyContent: 'left',margin: 'auto', alignItems: 'left'}}>
                        {puntos.map((value) => {
                                return (
                                    <ul className='ul_ODS'>
                                    <li style={{textAlign: 'left'}}>
                                        {value.texto}
                                    </li>
                                    </ul>
                                )
                            })}
                    </div>
                    


                    <div style={{backgroundColor:'#3734362e', width:'80%', height:'1px',justifyContent: 'center',margin: 'auto', alignItems: 'center'}}></div>
                    <div style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop:'10px',
                                }}>
                        <div className='grid_img_ODS' style={{width:'90%', justifyContent: 'center',margin: 'auto', alignItems: 'center'}}>
                                {ods.map((value) => {
                                    return (
                                        <img src={value.img} alt='' height='70px' width='70px'  />
                                        )
                                })}
                        </div>
                       
                    </div>
                </div>
        </div>
        </div>
    )

}
Card.propTypes = {
    linea: PropTypes.string.isRequired,
    linea2: PropTypes.string,
    letra: PropTypes.string,
    foto_background: PropTypes.string,
    puntos: PropTypes.string,
    ods:PropTypes.string,
  };
export default Card