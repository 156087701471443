export const Data = [
    {
      title: 'Ponencia Invitada: Dr. Daniel Valero Huerta',
      startDate: new Date(2023, 9, 17, 19, 0),
      endDate: new Date(2023, 9, 17, 19, 30),
      id: 0,      
      color: '#FF9800',
      colorText:'white',
      textSize:'0.8rem',
      title_work: [
        {
          texto:'Ponencia:',
          titulo:'Carrera investigadora en centro Europa - oportunidades, dificultades y una perspectiva personal en ingeniería del agua'
        }
      ],
      location: [
        {
          texto:'Sala:',
          lugar:'Salón de Actos de la E.T.S. de Ingeniería de Caminos, Canales y Puertos y de Ingeniería de Minas '
        }
      ],
      
    }, {
      title: 'Cursos Pre-Congreso para profesionales, investigadores y estudiantes universitarios relacionados con la Ingeniería del Agua: Diseño y dimensionamiento de SUDS, TETIS, Modelización hidráulica mediante software IBER. Iniciación y Avanzado, Curso Herramienta de análisis y tratamiento de datos de monitorización del Ciclo integral del Agua y trabajos de hidráulica experimental',
      startDate: new Date(2023, 9, 17, 9, 0),
      endDate: new Date(2023, 9, 17, 18,0),
      id: 1,
      color: '#90A4AE',
      colorText:'white',
      textSize:'0.8rem',
      location: [
        {
          texto:'',
          lugar:''
        }
      ],     
    }, 
    {
        title: 'Recepción',
        startDate: new Date(2023, 9, 17, 18, 30),
        endDate: new Date(2023, 9, 17, 19,0),
        id: 3,
        color: '#212121',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],        
      }, 
      {
        title: 'Visita guiada Cartagena',
        startDate: new Date(2023, 9, 17, 19, 30),
        endDate: new Date(2023, 9, 17, 21,30),
        id: 3,
        color: '#C51162',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Actividad YPN',
        startDate: new Date(2023, 9, 17, 21, 30),
        endDate: new Date(2023, 9, 17, 23,0),
        id: 4,
        color: '#EF5350',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },


      {
        title: 'Recogida de documentación',
        startDate: new Date(2023, 9, 18, 8, 0),
        endDate: new Date(2023, 9, 18, 9,0),
        id: 5,
        color: '#37474F',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Sesión inaugural. In memoriam Profesor Dr. Manuel Gómez Valentín',
        startDate: new Date(2023, 9, 18, 9, 0),
        endDate: new Date(2023, 9, 18, 9,45),
        id: 6,
        color: '#212121',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Ponencia Invitada: Prof. Dr. Jorge Matos',
        startDate: new Date(2023, 9, 18, 9, 45),
        endDate: new Date(2023, 9, 18, 10,15),
        id: 7,
        color: '#FF9800',
        colorText:'white',
        textSize:'0.8rem',
        
        title_work: [
          {
            texto:'Ponencia:',
            titulo:'Hydraulic structures'
          }
        ],
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Pausa Café',
        startDate: new Date(2023, 9, 18, 10, 15),
        endDate: new Date(2023, 9, 18, 10,45),
        id: 8,
        color: '#009688',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Sesiones Orales',
        startDate: new Date(2023, 9, 18, 10, 45),
        endDate: new Date(2023, 9, 18, 12,15),
        id: 9,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Línea temática A1:',
            lugar:'Salón de grados'
          },
          {
            texto:'Línea temática B1:',
            lugar:'Paraninfo'
          },
          {
            texto:'Línea temática C1:',
            lugar:'Aula Sebastián Feringán'
          },
          {
            texto:'Línea temática D1:',
            lugar:'Aula de Usos Múltiples'
          }
        ],

      },
      {
        title: 'Pausa',
        startDate: new Date(2023, 9, 18, 12, 15),
        endDate: new Date(2023, 9, 18, 12,30),
        id: 10,
        color: '#009688',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Sesiones Orales',
        startDate: new Date(2023, 9, 18, 12, 30),
        endDate: new Date(2023, 9, 18, 14,0),
        id: 11,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Línea temática A2:',
            lugar:'Salón de grados'
          },
          {
            texto:'Línea temática B2:',
            lugar:'Paraninfo'
          },
          {
            texto:'Línea temática C2:',
            lugar:'Aula Sebastián Feringán'
          },
          {
            texto:'Línea temática D2:',
            lugar:'Aula de Usos Múltiples'
          }
        ],
      },
      {
        title: 'Comida',
        startDate: new Date(2023, 9, 18, 14, 0),
        endDate: new Date(2023, 9, 18, 15,0),
        id: 12,
        color: '#673AB7',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      
      {
        title: 'Sesiones Orales',
        startDate: new Date(2023, 9, 18, 15, 0),
        endDate: new Date(2023, 9, 18, 16,45),
        id: 14,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Línea temática A3:',
            lugar:'Salón de grados'
          },
          {
            texto:'Línea temática B3:',
            lugar:'Paraninfo'
          },
          {
            texto:'Línea temática C3:',
            lugar:'Aula Sebastián Feringán'
          },
          {
            texto:'Línea temática D3:',
            lugar:'Aula de Usos Múltiples'
          }
        ],
      },
      {
        title: 'Pausa Café',
        startDate: new Date(2023, 9, 18, 16, 45),
        endDate: new Date(2023, 9, 18, 17,15),
        id: 15,
        color: '#009688',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Sesiones Orales',
        startDate: new Date(2023, 9, 18, 17, 15),
        endDate: new Date(2023, 9, 18, 18,45),
        id: 16,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Línea temática A4:',
            lugar:'Salón de grados'
          },
          {
            texto:'Línea temática B4:',
            lugar:'Paraninfo'
          },
          {
            texto:'Línea temática C4:',
            lugar:'Aula Sebastián Feringán'
          },
          {
            texto:'Línea temática D4:',
            lugar:'Aula de Usos Múltiples'
          }
        ],
      },
     
      {
        title: 'Sesiones Póster',
        startDate: new Date(2023, 9, 18, 19, 15),
        endDate: new Date(2023, 9, 18, 20,45),
        id: 18,
        color: '#795548',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Soportales Edificio',
            lugar:'Antiguo Hospital de Marina'
          }
        ],
      },
      {
        title: 'Cena Congreso',
        startDate: new Date(2023, 9, 18, 21, 30),
        endDate: new Date(2023, 9, 18, 23,30),
        id: 19,
        color: '#673AB7',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },


      {
        title: 'Recogida documentación',
        startDate: new Date(2023, 9, 19, 8, 30),
        endDate: new Date(2023, 9, 19, 9,0),
        id: 20,
        color: '#37474F',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Presentación Monográfico',
        startDate: new Date(2023, 9, 19, 9, 0),
        endDate: new Date(2023, 9, 19, 9,15),
        id: 21,
        color: '#00B8D4',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Ponencia Invitada: Dña. Inmaculada Serrano Sánchez',
        startDate: new Date(2023, 9, 19, 9, 15),
        endDate: new Date(2023, 9, 19, 9,45),
        id: 22,
        color: '#FF9800',
        colorText:'white',
        textSize:'0.8rem',
        title_work: [
          {
            texto:'Ponencia:',
            titulo:'La gestión del agua urbana como paradigma de la circularidad en la Región de Murcia'
          }
        ],
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Sesiones Orales',
        startDate: new Date(2023, 9, 19, 9, 45),
        endDate: new Date(2023, 9, 19, 10,15),
        id: 23,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Línea temática M1:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Pausa Café',
        startDate: new Date(2023, 9, 19, 10, 15),
        endDate: new Date(2023, 9, 19, 10,45),
        id: 24,
        color: '#009688',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Sesiones Orales Monográfico',
        startDate: new Date(2023, 9, 19, 11, 15),
        endDate: new Date(2023, 9, 19, 12,15),
        id: 25,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Línea temática M2:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Pausa',
        startDate: new Date(2023, 9, 19, 12, 15),
        endDate: new Date(2023, 9, 19, 12,30),
        id: 26,
        color: '#009688',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Sesiones Orales Monográfico',
        startDate: new Date(2023, 9, 19, 13, 0),
        endDate: new Date(2023, 9, 19, 14,0),
        id: 27,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },

      {
        title: 'Ponencia Invitada: Prof. Dra. Petra Amparo López Jiménez',
        startDate: new Date(2023, 9, 19, 12, 30),
        endDate: new Date(2023, 9, 19, 13,0),
        id: 29,
        color: '#FF9800',
        colorText:'white',
        textSize:'0.8rem',
        title_work: [
          {
            texto:'Ponencia:',
            titulo:'"Evaluando la Sostenibilidad en la Ingeniería del Agua'
          }
        ],
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Comida',
        startDate: new Date(2023, 9, 19, 14, 0),
        endDate: new Date(2023, 9, 19, 15,0),
        id: 28,
        color: '#673AB7',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Ponencia Invitada: Dr. Teodoro Estrela Monreal',
        startDate: new Date(2023, 9, 19, 15, 0),
        endDate: new Date(2023, 9, 19, 15,30),
        id: 29,
        color: '#FF9800',
        colorText:'white',
        textSize:'0.7rem',
        title_work: [
          {
            texto:'Ponencia:',
            titulo:'Adaptación al Cambio Climático en la gestión del agua en España'
          }
        ],
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },
     
      {
        title: 'Sesiones Orales Monográfico',
        startDate: new Date(2023, 9, 19, 15, 30),
        endDate: new Date(2023, 9, 19, 16,45),
        id: 30,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Pausa Café',
        startDate: new Date(2023, 9, 19, 16, 45),
        endDate: new Date(2023, 9, 19, 17,15),
        id: 31,
        color: '#009688',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },
      {
        title: 'Sesiones Orales Monográfico',
        startDate: new Date(2023, 9, 19, 17, 15),
        endDate: new Date(2023, 9, 19, 18,15),
        id: 32,
        color: '#1976D2',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Ponencia Invitada: D. Juan Ojeda Couchoud',
        startDate: new Date(2023, 9, 19, 18, 15),
        endDate: new Date(2023, 9, 19, 18,45),
        id: 7,
        color: '#FF9800',
        colorText:'white',
        textSize:'0.8rem',
        
        title_work: [
          {
            texto:'Ponencia:',
            titulo:'Estado sedimentológico de embalses en España. Futuro y estrategias de operación'
          }
        ],
        location: [
          {
            texto:'Sala:',
            lugar:'Paraninfo'
          }
        ],
      },

      {
        title: 'Sesión Clausura',
        startDate: new Date(2023, 9, 19, 18, 45),
        endDate: new Date(2023, 9, 19, 19,0),
        id: 33,
        color: '#212121',
        colorText:'white',
        textSize:'0.8rem',
        location: [
          {
            texto:'',
            lugar:''
          }
        ],
      },

      {
        title: 'Ponencia Invitada: D. Juan Cascales Salinas',
        startDate: new Date(2023, 9, 18, 18, 45),
        endDate: new Date(2023, 9, 18, 19,15),
        id: 36,
        color: '#FF9800',
        colorText:'white',
        textSize:'0.8rem',
        title_work: [
          {
            texto:'Ponencia:',
            titulo:'El binomio agua‐energía: el caso de la Mancomunidad de los Canales del Taibilla'
          }
        ],
        location: [
          {
            texto:'Sala',
            lugar:'Paraninfo'
          }
        ],
      },
      {
        title: 'Ponencia Invitada: Dña Francisca Baraza Martínez',
        startDate: new Date(2023, 9, 19, 10, 45),
        endDate: new Date(2023, 9, 19, 11,15),
        id: 37,
        color: '#FF9800',
        colorText:'white',
        textSize:'0.8rem',
        title_work: [
          {
            texto:'Ponencia:',
            titulo:'Las soluciones basadas en la naturaleza en el Marco de Actuaciones Prioritarias para la recuperación del Mar Menor'
          }
        ],
        location: [
          {
            texto:'Sala',
            lugar:'Paraninfo'
          }
        ],
      },
      
     


     


  ];
  