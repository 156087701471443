import { fontWeight } from "@mui/system";
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";

import React from "react"
import { useEffect } from "react";

import { Space, Table, Tag,Badge } from 'antd';

import Data from "./Data"
import './comunicacionesorales.css'

const Pages = () => {
  useEffect(() => window.scrollTo(0, 0));

  
  const columns = [
    {
      title: 'Ponencia',
      dataIndex: 'titulo',
      key: 'titulo',
      render: text => <b style={{fontSize:'0.9rem'}}>{text}</b>,
    },

    {
      title: 'Autor',
      dataIndex: 'autor',
      key: 'autor',
      render: text => <p style={{fontSize:'0.9rem'}}>{text}</p>,
    },
    
    
  ].filter(item => !item.hidden);;
  
   return (
    
    <>
       <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Comunicaciones Orales</h3>
            <h1>Expande para más información</h1>
           
          </div>

            <div style={{width:'100%'}}>
                <Table 
                  columns={columns} 
                  dataSource={Data} 

                  expandable={{
                    
                      expandedRowRender: record => 
                      <div style={{width:'100%', display:'flex', flexDirection:'row',  justifyContent: 'left', alignContent:'left'}}>
                        <div style={{width:'80%'}}>
                          <p style={{ margin: '10px 0px 10px 60px' }}><b>Institución:</b> {record.institucion}</p>

                          <div style={{width:'100%',  flexDirection:'row',  justifyContent: 'left', alignContent:'left'}}>
                            <p style={{ margin: '10px 0px 10px 60px' }}><b>Día:</b> {record.dia}</p>
                            <p style={{ margin: '10px 0px 10px 60px' }}><b>Hora:</b> {record.hora}</p>
                          </div>
                          <p style={{ margin: '10px 0px 10px 60px' }}><b>Edificio:</b> {record.lugar}</p>
                          <p style={{ margin: '10px 0px 10px 60px' }}><b>Sala:</b> {record.sala}</p>

                         

                        </div>

                        
                      </div>
                    
                    ,
                    // expandIconColumnIndex:7
                    expandRowByClick: false,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                          <MinusCircleTwoTone style={{fontSize:'20px'}} onClick={e => onExpand(record, e)} />
                        ) : (
                          <PlusCircleTwoTone style={{fontSize:'20px'}} onClick={e => onExpand(record, e)} />
                        )
                    }}

                

                />
            </div>
          
        </div>      
        
      </section>
    </>
  )
}

export default Pages
