import React from "react"
import { useEffect } from "react";


const InscripcionSymposiumPage = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    
    <>
  <iframe src="https://www.upct.es/" height="4500px" width="100%"/>,

    </>
  )
}

export default InscripcionSymposiumPage
