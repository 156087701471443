import React from "react"
import { useEffect } from "react";
import './monografico.css'


const Monografico = () => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Monográfico</h3>
            <h1>La Resiliencia de las Infraestructuras Hidráulicas ante el Cambio Climático</h1>
          </div>
          <div style={{margin:'0px 0px 20px 0px'}}>
                      
            <div style={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         margin:'auto',
                         marginBottom:'20px'
                         }}>

                   <div>     
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    La resiliencia, entendida como la capacidad de adaptación de un sistema hidráulico a diferentes escenarios extremos, introduce una nueva perspectiva de evaluación de la operatividad de las infraestructuras, puesto que su destrucción tendría un impacto debilitante en la seguridad, la economía, la salud pública y el medio ambiente. Dicha perspectiva permitirá cuantificar y prever los posibles daños, proponer medidas encaminadas a restablecer la operatividad y reducir el riesgo para mejorar dicha resiliencia. 
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem', marginTop:'20px'}}>
                    El monográfico está en consonancia con los grandes retos de la sociedad actual y los Objetivos de Desarrollo Sostenible (ODS) de las Naciones Unidas, cuya finalidad es poner fin a la pobreza, reducir la desigualdad y luchar contra el Cambio Climático y, concretamente, con dos objetivos de la Agenda 2030 para el Desarrollo Sostenible:
                  </p>
                  <div style={{ margin:'20px 10px 20px 10px',width:'100%', display:'flex', flexDirection:'row',  justifyContent: 'center', alignContent:'center'}}>
                  
                  <div className='grid_ods_monografico'>
                         <div>
                            <img src='/assets/ods/odslogos/ODS1.jpg' alt='' height='180px' width='180px'  />
                            <h4 style={{textAlign:'center',  fontSize:'0.9rem', color:'#000'}}>
                              Poner fin a la pobreza en todas sus formas en todo el mundo
                            </h4>
                         </div>
                           
                         <div>
                            <img src='/assets/ods/odslogos/ODS6.jpg' alt='' height='180px' width='180px'  />
                            <h4 style={{textAlign:'center',  fontSize:'0.9rem', color:'#000'}}>
                                  Garantizar la disponibilidad de agua, su gestión sostenible y el saneamiento para todos
                            </h4>
                         </div>

                         <div>
                            <img src='/assets/ods/odslogos/ODS13.jpg' alt='' height='180px' width='180px'  />
                            <h4 style={{textAlign:'center',  fontSize:'0.9rem', color:'#000'}}>
                                  Adoptar medidas urgentes para combatir el Cambio Climático y sus efectos
                            </h4>
                         </div>
                  </div>

                  </div>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    El objetivo del Monográfico es compartir experiencias y poner en común estudios en los que se aborda tanto la evaluación de la resiliencia como la capacidad de las infraestructuras hidráulicas para adaptarse a las condiciones cambiantes y resistir y recuperarse rápidamente de las perturbaciones debidas a las posibles modificaciones derivadas del Cambio Climático
                  </p>
                  </div>
            </div>

          </div>          
          </div>      
        
      </section>
    </>
  )
}

export default Monografico
