import React, {useState} from 'react'
import PropTypes from "prop-types";
import { Avatar, List } from 'antd';



import './Card.css'
function Card( {titulo, autores, institucion, descripcion, foto_poster}){

    return(
        <div className='Card_poster' style={{margin:'auto',   flex:'auto', width:'100%', alignContent:'center', alignItems:'center'}}>
                <img src={foto_poster} style={{height:'380px', borderRadius:'10%'}} />                    
                <div style={{backgroundColor:'#3734362e', width:'90%', height:'1px',justifyContent: 'center',margin: 'auto', alignItems: 'center'}}></div>
                <h4 style={{textAlign:'justify', marginTop:'20px', fontSize:'0.9rem', color:'#000'}}>
                        {titulo}
                </h4>
            
        </div>

    )

}
Card.propTypes = {
    titulo: PropTypes.string.isRequired,
    autores: PropTypes.string,
    institucion: PropTypes.string,
    descripcion: PropTypes.string,
    foto_poster: PropTypes.string,
    

  };
export default Card