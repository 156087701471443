const DataColaboradores = [

  {
    id: 20,
    nombre: 'Ministerio para la Transición Ecológica y Reto Demográfico',
    logo:'assets/footer/colaboradores/MITECO.png',
    url:'https://www.miteco.gob.es/es/',
    height:'60px'     
  },

  {
    id: 11,
    nombre: 'Mancomunidad de los Canales del Taibilla',
    logo:'assets/footer/colaboradores/Mancomunidad de los Canales del Taibilla.png',
    url:'https://www.mct.es/web/mct/inicio',
    height:'55px'     
  },
  {
    id: 8,
    nombre: 'Excmo. Ayuntamiento de Cartagena',
    logo:'assets/footer/colaboradores/Ayto Cartagena.png',
    url:'https://www.cartagena.es/'    ,
    height:'110px' 
  },
  {
    id: 5,
    nombre: 'Comunidad Autónoma de la Región de Murcia',
    logo:'assets/footer/colaboradores/Rmurcia.jpg',
    url:'http://www.carm.es/web/pagina?IDCONTENIDO=80&IDTIPO=140&RASTRO=c$m22660' ,
    height:'80px'    
  },
  {
    id: 7,
    nombre: 'Entidad Regional de Saneamiento y Depuración de la Región de Murcia',
    logo:'assets/footer/colaboradores/Esamur.jpg',
    url:'https://www.esamur.com/'    ,
    height:'55px' 
  },

  {
    id: 9,
    nombre: 'Comunidad de Regantes del Campo de cartagena',
    logo:'assets/footer/colaboradores/CRCC.jpg',
    url:'https://www.crcc.es/'    ,
    height:'65px' 
  },

  {
    id: 19,
    nombre: 'Red de Laboratorios de Hidráulicas de España',
    logo:'assets/footer/colaboradores/RLHE.jpg',
    url:'https://www.iahr.org/index/detail/602',
    height:'75px'     
  },

  {
    id: 17,
    nombre: 'Fundación Séneca - Agencia de Ciencia y Tecnología de la Región de Murcia',
    logo:'assets/footer/colaboradores/fseneca.png',
    url:'https://fseneca.es/',
    height:'65px'     
  },
  {
    id: 1,
    nombre: 'Colegio de Ingenieros de Caminos Canales y Puertos Demarcación de Murcia',
    logo:'assets/footer/colaboradores/Colegio Caminos Murcia.png',
    url:'http://www.caminosmurcia.es/',
    height:'110px'  
  },

  {
    id: 15,
    nombre: 'Grupo TYPSA',
    logo:'assets/footer/colaboradores/GRupo Typsa.png',
    url:'https://www.typsa.com/'   ,
    height:'75px'  
  },
  {
    id: 16,
    nombre: 'Técnicas Reunidas',
    logo:'assets/footer/colaboradores/Tecnicas Reunidas.png',
    url:'https://www.tecnicasreunidas.es/' ,
    height:'95px'    
  },
  {
    id: 18,
    nombre: 'IAHR Spain Young Professionals Network',
    logo:'assets/footer/colaboradores/IAHR YPN.png',
    url:'https://www.iahr.org/index/committe/121',
    height:'65px'     
  },
  {
    id: 2,
    nombre: 'Hidrogea, Gestion Integral de las Aguas S.L.',
    logo:'assets/footer/colaboradores/Hidrogea.jpg',
    url:'https://www.hidrogea.es/'  ,
    height:'55px'   
  },
  {
    id: 14,
    nombre: 'Comité Nacional Español de Grandes Presas',
    logo:'assets/footer/colaboradores/Spancold.jpg',
    url:'https://www.spancold.org/'  ,
    height:'75px'   
  },
  {
    id: 13,
    nombre: 'Regenera Levante- Servicios, energía y medio ambiente',
    logo:'assets/footer/colaboradores/Regenera Levante.jpg',
    url:'https://regeneralevante.com/'  ,
    height:'55px'   
  },
  {
    id: 6,
    nombre: 'Aguas de Murcia',
    logo:'assets/footer/colaboradores/Aguas de Murcia.png',
    url:'https://www.emuasa.es/inicio'    ,
    height:'85px' 
  },



  
  







 

  



 
];
export default DataColaboradores