import React, {useState} from 'react'
import PropTypes from "prop-types";

import './Card.css'
function Card( {plan, precio, puntos, gradiente1, gradiente2, foto_background}){
    return(
        <div className='Encabecado_Card_inscripcion'>
    
        <div className = 'Card_inscripcion'>
                <div className = 'upper-container_inscripcion' style={{background: `linear-gradient(to right, ${gradiente1}, ${gradiente2})`}}>
                <h3 style={{color:'#fff', fontSize:'20px', padding:'9px 0px 5px 0px'}}>{plan}</h3>

                </div>

                <div style={{padding:'10px 10px 10px 10px'}}>
                    <h3 style={{color:'#000', fontSize:'20px', margin:'auto', display:'flex'}}>Incluye:</h3>
                </div>

                <div className ='lower-container_inscripcion'>
                    
                    <div style={{height:'130px', width:'85%', justifyContent: 'left',margin: 'auto', alignItems: 'left'}}>
                        {puntos.map((value) => {
                                return (
                                    <ul className='ul_inscripcion'>
                                    <li style={{textAlign: 'left'}}>
                                        {value.texto}
                                    </li>
                                    </ul>
                                )
                            })}
                    </div>
                    


                    <div style={{backgroundColor:'#3734362e', width:'80%', height:'1px',justifyContent: 'center',margin: 'auto', alignItems: 'center'}}></div>
                    <div style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop:'0px',
                                }}>
                        
                        <h3 style={{color:'#000', fontSize:'40px', margin:'auto', alignContent:'left', alignItems:'left'}}>{precio}</h3>

                       
                    </div>
                </div>
        </div>
        </div>
    )

}
Card.propTypes = {
    plan: PropTypes.string.isRequired,
    foto_background: PropTypes.string,
    puntos: PropTypes.string,
    precio:PropTypes.string,
    gradiente1:PropTypes.string,
    gradiente2:PropTypes.string
  };
export default Card