import React, { useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import { Link } from "react-router-dom"
import { DownOutlined} from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { FaHotel, FaPlaceOfWorship, FaCar, FaBookmark,FaFeatherAlt, FaAward, FaChalkboardTeacher} from "react-icons/fa";
import { AiFillSchedule,AiFillPicture,AiFillFilePdf, AiFillFileText,AiFillCrown } from "react-icons/ai";
import { MdTopic,MdPerson,MdMeetingRoom } from "react-icons/md";
import { BsMic } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';

const Header = () => {
  const [sidebar, setSidebar] = useState(false)
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header")
    header.classList.toggle("active", window.scrollY > 50)
  })

  const menu_programa = (
    <Menu
      items={[
        {
          key: '0',
          label: (
            <a href="/assets/docs/PROGRAMA JIA CARTAGENA.pdf" target="_blank" rel="noreferrer">Programa Completo</a>
          ),
          icon: <AiFillFileText />,
        },
        {
          key: '1',
          label: (
            <a href="/assets/docs/Programa detallado VII JIA.pdf" target="_blank" rel="noreferrer">Programa Detallado</a>
          ),
          icon: <AiFillFilePdf />,
        },
        {
          key: '2',
          label: (
            <Link to='/programacion_jornada'>Programación Jornada</Link>
          ),
          icon: <AiFillSchedule />,
        },
        {
          key: '3',
          label: (
            <Link to='/salas'>Salas temáticas</Link>
          ),
          icon: <MdMeetingRoom />,
        },
        {
          key: '4',
          label: (
            <Link to='/monografico'>Monográfico</Link>
          ),
          icon: <MdTopic />,
        },
        {
          key: '5',
          label: (
            <Link to='/ponencias_invitadas'>Ponencias invitadas</Link>
          ),
          icon: <MdPerson />,
        },
        {
          key: '6',
          label: (
            <Link to='/curso_iber_basico'>Curso Pre-Congreso: IBER-Básico</Link>
          ),
          icon: <FaChalkboardTeacher />,
        },
        {
          key: '7',
          label: (
            <Link to='/curso_iber_avanzado'>Curso Pre-Congreso: IBER-Avanzado</Link>
          ),
          icon: <FaChalkboardTeacher />,
        },
        {
          key: '8',
          label: (
            <Link to='/curso_suds'>Curso Pre-Congreso: SUDS</Link>
          ),
          icon: <FaChalkboardTeacher />,
        },
        {
          key: '9',
          label: (
            <Link to='/curso_tetis'>Curso Pre-Congreso: TETIS V9.1</Link>
          ),
          icon: <FaChalkboardTeacher />,
        },
        {
          key: '10',
          label: (
            <Link to='/curso_workshop_udmt'>Curso Pre-Congreso: WorkshopUDMT</Link>
          ),
          icon: <FaChalkboardTeacher />,
        },

        {
          key: '11',
          label: (
            <Link to='/comunicaciones_orales'>Comunicaciones tipo oral</Link>
          ),
          icon: <BsMic />,
        },
        {
          key: '12',
          label: (
            <Link to='/posters'>Comunicaciones tipo poster</Link>
          ),
          icon: <AiFillPicture />,
        },
        

        
      ]}
    />
  );

  const menu_logistica = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Link to='/sede'>Sede</Link>
          ),
          icon:<FaPlaceOfWorship/>
        },
        {
          key: '2',
          label: (
            <Link to='/alojamiento'>Alojamiento</Link>
          ),
          icon: <FaHotel />,
        },
        {
          key: '3',
          label: (
            <Link to='/desplazamiento'>Desplazamiento</Link>
          ),
          icon: <FaCar />,
          
        },
        {
          key: '4',
          label: (
            <a href="/assets/docs/Gincana.pdf" target="_blank" rel="noreferrer">Gincana</a>
          ),
          
          icon: <AiFillCrown />,
        },
      ]}
    />
  );
  const menu_instrucciones = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Link to='/inscripcion'>Inscripción</Link>
          ),
          icon:<FaBookmark/>
        },
        {
          key: '2',
          label: (
            <Link to='/autores'>Autores</Link>
          ),
          icon: <FaFeatherAlt />,
        },
        {
          key: '3',
          label: (
            <Link to='/premios'>Premios</Link>
          ),
          icon: <FaAward />,
        }
      ]}
    />
  );
  
  return (
    
    <>
    
      <header className='header'>
        <div className="organizacion_cabecera">
        <Link to='/'>
          <div  className="organizacion_logo_texto">
          
              
              
              <img style={{height: 80 , alignSelf: 'center', display:'flex', margin:'auto'}}  src='assets/logojia2023.png' alt='' />
              <div>
                  <h4 style={{ lineHeight: '30px'}}> VII Jornadas de Ingeniería del Agua</h4>
                  <p style={{ lineHeight: '0px'}}>Universidad Politécnica de Cartagena</p>
              </div>

          </div>
          </Link>
          

          <div className="organizacion_opciones_menu">
            <ul >
              <li>
                <Link to='/'>Inicio</Link>
              </li>
              <li>
                <Link to='/fechas_clave'>Fechas clave</Link>
              </li>
              
              <li>
              < Link to='/organizacion'>Organización</Link>
              </li>
              <li>
              <Dropdown overlay={menu_programa}>
                <a onClick={e => e.preventDefault()}>
                    <Space>
                    Programa
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
              </li>
              <li>
              <Dropdown overlay={menu_instrucciones}>
                <a onClick={e => e.preventDefault()}>
                    <Space>
                    Instrucciones
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
              </li>
              
              <li>
              <Dropdown overlay={menu_logistica}>
                <a onClick={e => e.preventDefault()}>
                    <Space>
                    Logística
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
              </li>
              <li>
              <a style={{}} href="https://www.verticesur.es/congresos/jia2023/inscription/add_new" target="_blank" rel="noopener noreferrer">
                    Inscripción
              </a>
            
              
              </li>
              
            </ul>
          </div>
          
        </div>
      </header>
    </>
  )
}

export default Header
