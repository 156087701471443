import React from "react"
import { useEffect, useState  } from "react";
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import Moment from 'moment';
import {
  Scheduler,
  WeekView,
  Appointments,
  AllDayPanel,
  AppointmentTooltip  
} from '@devexpress/dx-react-scheduler-material-ui';



import { Data } from './Data';

const currentDate = '2023-10-17';



const AppointmentContent = (props) => {
  const { data, style } = props;

  return (
    //Esto controla la etiqueta de Texto
    <Appointments.AppointmentContent
      style={{
        ...style,
        color: 'white'
      }}
      {...props}
    />
  );
};
//Esto es como se renderiza el appointmente
const Appointment = ({ children, style, data, ...restProps }) => (
  
  <Appointments.Appointment
    {...restProps}
    data={data}
    style={{
      ...style,
      backgroundColor: data.color,
    }}
  >

    <div>
      <p style={{color:'white', fontSize: window.innerWidth<600 ? ((parseFloat(data.textSize) * window.innerWidth/900) + 'rem') :((parseFloat(data.textSize) * window.innerWidth/1700) + 'rem')}}> <b>{data.title}</b></p>      
      
      <p style={{color:'white', fontSize: window.innerWidth<600 ? ((parseFloat(data.textSize) * window.innerWidth/900) + 'rem') :((parseFloat(data.textSize) * window.innerWidth/1700) + 'rem')}}> 
      {data.startDate.getHours() < 10 ? '0'+data.startDate.getHours():data.startDate.getHours()}:{data.startDate.getMinutes() < 10 ? '0'+data.startDate.getMinutes():data.startDate.getMinutes()}-{data.endDate.getHours() < 10 ? '0'+data.endDate.getHours():data.endDate.getHours()}:{data.endDate.getMinutes() < 10 ? '0'+data.endDate.getMinutes():data.endDate.getMinutes()}</p>
  
    </div>
    

  </Appointments.Appointment>
);

const Detalle_Tooltip = (({
  children, appointmentData, ...restProps
}) => (
  <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
   <div style={{marginLeft:'20px'}}>
   {appointmentData.location.map((value) => {
          return (
                 <p style={{color:'black', fontSize:'1rem'}}> <b>{value.texto} </b>{value.lugar}</p>     
                 )
    })}

    
   </div>
  </AppointmentTooltip.Content>
));






const Eventos = () => {
  useEffect(() => window.scrollTo(0, 0));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [excludedDays, setExcludedDays] = useState([0, 1, 6]);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 600) {
      setExcludedDays([0, 1, 5, 6]);
    } else {
      setExcludedDays([0, 1,5, 6]);
    }
  }, [screenWidth]);


  return (
    <>
      <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Programación Jornadas</h3>
            <h1>¡Descubre todo lo que está por venir!</h1>
            <p style={{fontSize:'1rem', color:'grey', margin:'10px'}}>Pulsa sobre cada bloque para más información</p>

          </div>
          
          
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Paper>
            <Scheduler
              data={Data}
              locale='es-ES'

              >
              <ViewState
                defaultCurrentDate={currentDate}
              />
              <WeekView
                startDayHour={8}
                endDayHour={23}
                excludedDays={excludedDays}
                
                name={['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes','Sábado', 'Domingo']}
              />
              <Appointments
                appointmentComponent={Appointment}
                appointmentContentComponent={AppointmentContent}

                placeAppointmentsNextToEachOther={'true'}
              />


          <AppointmentTooltip            
            contentComponent={Detalle_Tooltip}   
            showCloseButton         
          />
            </Scheduler>

          </Paper>
          </div>
           </div>      
        
      </section>
    </>
  )
}

export default Eventos
