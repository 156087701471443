const Data = [
  {
    id: 1,
    plan: "Inscripción bonificada",
    precio:'300€',
    gradiente1:'#297909',
    gradiente2:'#00ff10',
    foto_background:"/assets/ods/bgcabecera/bf_dinamica_fluvial.jpg",
    puntos:[
      {
        id:1,
        texto:'Antes del 14 de junio',
      },
      {
        id:2,
        texto:'Acceso a la JIA 2023',
      },
      {
        id:3,
        texto:'Permite la publicacion de los artículos en el libro de actas',
      },
      
    ]
  },
  {
    id: 2,
    plan: "Inscripción normal",
    precio:'350€',
    gradiente1:'#090979',
    gradiente2:'#00d4ff',
    foto_background:"/assets/ods/bgcabecera/bf_dinamica_fluvial.jpg",
    puntos:[
      {
        id:1,
        texto:'Entre el 15 de junio y el 15 de septiembre',
      },
      {
        id:2,
        texto:'Acceso a las JIA 2023',
      },
      {
        id:3,
        texto:'Permite la publicacion de los artículos en el libro de actas (*)',
      },
      
    ]
  },
  {
    id: 3,
    plan: "Inscripción tardía",
    precio:'400€',
    gradiente1:'#793209',
    gradiente2:'#ff3f00',
    foto_background:"/assets/ods/bgcabecera/bf_dinamica_fluvial.jpg",
    puntos:[
      {
        id:1,
        texto:'Entre el 16 de septiembre y el 15 de octubre de 2023 (**)',
      },
      {
        id:2,
        texto:'Acceso a la JIA 2023',
      },
    
      
    ]
  },
  

  

];
export default Data