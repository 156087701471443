import React from "react"
import { Link } from "react-router-dom"
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { GiPositionMarker } from "react-icons/gi";
import { HiMail } from "react-icons/hi";


const Footer = () => {
  return (
    <>
      <footer>
        <div style={{backgroundColor:'#08071c', width:'100%', height:'5px', display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'}}></div>
        <div className='container_footer_fit grid4' style={{marginTop:'20px'}}>
          <div className='box' style={{flex:'auto', alignContent:'center', alignItems:'center', margin:'auto'}}>
            <img src='./assets/logojia2023.png' alt='' style={{height:'100px',  margin:'auto', display: 'flex',justifyContent: 'center',alignItems: 'center'}}/>
            <p>VII Jornadas de Ingeniería del agua</p>
            <div className='SocailIcon' style={{marginTop:'20px',  display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
              <div style={{display:'none'}}>
                <i className='fab fa-facebook-f facebook'></i>
              <i className='fab fa-instagram instagram'></i>
              <i className='fab fa-twitter twitter'></i>
              <i className='fab fa-youtube youtube'></i>
              <i className='fab fa-pinterest pinterest'></i>
              </div>
              

            </div>           
          </div>

          <div className='box'>
            <h2>ENLACES DE INTERÉS</h2>
            <ul>
              <li> <Link to='/'>Inicio</Link></li>
              <li> <Link to='/fechas_clave'>Fechas clave</Link></li>
              <li> <Link to='/organizacion'>Organización</Link></li>
              <li> <Link to='/programacion_jornada'>Programación Jornada</Link></li>
              <li> <Link to='/ponencias_invitadas'>Ponencias</Link></li>

              <li>
              <a style={{}} href="https://www.verticesur.es/congresos/jia2023/inscription/add_new" target="_blank" rel="noopener noreferrer">
                    Inscripción
              </a>
                
                
                </li>
              
              </ul>
          </div>
          <div className='box'>
            <h2>PROMOTORES</h2>
            <div  className="img-container-gris" style={{ alignItems: 'center', display:'flex'}}> 
                <Tooltip title="The International Association for Hydro-Environment Engineering and Research" TransitionComponent={Zoom}>        
                    <a href="https://www.iahr.org/" target="_blank" rel="noreferrer">        
                      <img style={{height: '100px'}}  src='assets/footer/promotores/IAHR.png' alt='IAHR' />  
                    </a>           
                </Tooltip>
                <Tooltip title="Fundación para el Fomento de la Ingeniería del Agua" TransitionComponent={Zoom}>       
                    <a href="https://www.afandaluzas.org/fundacion-para-el-fomento-de-la-ingenieria-del-agua/" target="_blank" rel="noreferrer">        
                        <img  style={{height: '60px' , marginLeft:'10px'}}  src='assets/footer/promotores/FFIA.png' alt='' />
                    </a>   
                </Tooltip>  
              </div>
            
          </div>
          <div className='box'>
            <h2>ORGANIZACIÓN</h2>
                  <div  className="img-container-gris" style={{ alignItems: 'center', display:'flex'}}>                    
                  <Tooltip title="Universidad Politécnica de Cartagena" TransitionComponent={Zoom}  >    
                    <a href="https://www.upct.es/" target="_blank" rel="noreferrer">        
                      <img  style={{width: '90%' }}  src='assets/footer/organizadores/upct-miembro-eut-azul.png' alt='' />
                    </a>      
                  </Tooltip>
                  </div>
                  <div style={{ margin:'0px',width:'100%', display:'flex', flexDirection:'row',  justifyContent: 'center', alignContent:'center'}}>
                  <div style={{width:'50%'}}>                       
                      <div  className="img-container-gris" style={{ alignItems: 'center', display:'flex'}}>                    
                        <Tooltip title="Escuela Técnica Superior de Ingeniería de Caminos, Canales y Puertos y de Ingeniería de Minas" TransitionComponent={Zoom}> 
                          <a href="https://caminosyminas.upct.es/" target="_blank" rel="noreferrer">        
                            <img style={{height: '100px', marginLeft:'10px'}}  src='assets/footer/organizadores/EICM.jpg' alt='' />
                          </a>       
                        </Tooltip>
                    </div>
                    </div>
              <div style={{width:'50%'}}>
                <div className="img-container-gris" style={{ alignItems: 'center', display:'flex', marginTop:'10px'}}>      
                    <Tooltip title="Grupo de investigación Hidr@m" TransitionComponent={Zoom}>    
                      <a href="https://www.upct.es/hidrom/" target="_blank" rel="noreferrer">        
                        <img style={{width: '90%', marginTop:'20px'}}  src='assets/footer/organizadores/hidrom.jpg' alt='' />
                      </a>
                    </Tooltip>
                  
                </div>
              </div>
              </div>
          </div>
          <div className='box'>
            <h2>JIA2023</h2>
              
              <p style={{textAlign:'justify', fontSize:'0.8rem'}}>            
                  ETS de Ingeniería de Caminos, Canales y Puertos y de Ingeniería de Minas
              </p>
              
              <p style={{textAlign:'justify', fontSize:'0.8rem', color:'#2d2a2aab'}}>            
                Paseo Alfonso XIII, 52
              </p>
              <p style={{textAlign:'justify', fontSize:'0.8rem', color:'#2d2a2aab'}}>            
                30203 Cartagena (Murcia, España)
              </p>   

              <div style={{display:'flex',  marginTop:'20px'}}>
                <HiMail  style={{fontSize: '20px', color:'#2d2a2aab', marginTop:'2px', marginRight:'5px'}} />
                  <a style={{textDecoration:'underline', fontSize:'0.9rem', alignContent:'center', alignItems:'center'}} href='mailto:congreso.jia2023@upct.es'>congreso.jia2023@upct.es</a>              
              </div>
             
              
              

            
          </div>

          
          
        </div>
        
        
        <div className='legal container'>
          <label style={{alignItems: 'right',alignContent:'right', margin:'auto'}}>
            Desarrollado por: <span style={{marginLeft:'3px'}}>  Dr. Daniel Carreres Prieto</span>
          </label>
        </div>
      </footer>
    </>
  )
}

export default Footer
