import { fontWeight } from "@mui/system";
import React from "react"
import { useEffect } from "react";
import Card from './Card'
import Data from "./Data"

const Pages = () => {
  useEffect(() => window.scrollTo(0, 0));
  

   return (
    
    <>
       <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Posters</h3>
            <h1>Explora la galería de trabajos presentados</h1>

      
          </div>

        

          <div style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex:'auto',
            margin:'auto'
            
          }}>
               <div className='card_grid_poster'>
             
               {Data.map((value) => {
                return (
                    <Card 
                    titulo={value.titulo}
                    autores={value.autores}
                    institucion={value.institucion}
                    descripcion={value.descripcion}
                    foto_poster={value.foto_poster}
                    />
                )
              })}
            </div>
          </div>
         

          
          
        </div>      
        
      </section>
    </>
  )
}

export default Pages
