import React from "react"
import { useEffect } from "react";
import './estilos.css'



const Curso_SUBS = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    <>
      <section className='Seccion_white_contenedor'>
            <div className='heading'>
              <h3>Curso SUDS</h3>
              <h1>Cómo implementar SUDS en nuestras ciudades: del concepto a la práctica</h1>
              <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>17 de octubre 2023</h1>

            </div>
      </section>
      <section >

        <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Horario del curso:</h1>

        <div className='container'>        
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Hora de inicio Bloque1: <b>9:30</b>
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Descanso: <b>11:30-12:00</b>
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Hora fin bloque 1: <b>14:00</b>
                </p>
                <br/>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    (comida de 14:00 a 15:00 de forma conjunta con el profesorado)
                </p>
                <br/>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Hora de inicio del bloque 2: <b>15:00</b>
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Hora fin bloque 2: <b>17:30</b>
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      (duración 2,5 horas)
                </p>
                
                <br/>

                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Duración curso: <b>6,5 horas</b>
                </p>

        </div> 
        <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Aula del curso:</h1>

          <div className='container'>        
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    <b>Aula G2</b> situada en la 1ª planta del Edificio ETSI de Caminos, Canales y Puertos y de Ingeniería de Minas situado en Paseo Alfonso XIII 52, 30203 Cartagena, Murcia (<b><a href="/assets/docs/Ubicacion Aulas Cursos Precongresos.pdf" target="_blank" rel="noreferrer">Pinchar para ver ubicación</a></b>)
                  </p>
                  
          </div>
        
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Profesores responsables del curso:</h1>

          <div className='container'>        
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                       Sara Perales Momparler (Green Blue Management) <a style={{textDecoration:'underline'}} href='mailto:sara.perales@greenbluemanagement.com'>sara.perales@greenbluemanagement.com</a>
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Ignacio Andrés Doménech (UPV) <a style={{textDecoration:'underline'}} href='mailto:igando@hma.upv.es'>igando@hma.upv.es</a>
                      </li>
              </ul>
        
          </div> 
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Contenido del curso:</h1> 
          <div className='container'> 
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Bloque 1. (4 horas | Mañana)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Desarrollo de los conceptos:      
                  </p>
                      <ul className='ul_autor'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            problemática
                          </li>
                      </ul>
                      <ul className='ul_autor'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Selección de SUDS
                          </li>
                      </ul>
                      <ul className='ul_autor'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Criterios de diseño
                          </li>
                      </ul>
                      <ul className='ul_autor'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Mantenimiento
                          </li>
                      </ul>
              </div>
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Bloque 2. (2.5 horas | Tarde)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Taller práctico de aplicación para plantear y poner en común un diseño concreto.
                  </p>
              </div>
              
          </div> 

          <div className='container' style={{marginBottom:'20px'}}> 
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                Se podrá asistir al curso completo (bloques 1+2) o sólo al primero (bloque 1), en función del interés del asistente.
                </p>                 
                            
          </div> 
      

          
              
        
         
          
      </section>
      

    </>
  )
}

export default Curso_SUBS
