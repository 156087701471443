const comite_organizador_cientifico_vocales= [
  

  {
    nombre:'Ignacio Andrés Domenech',
    centro:'UPV'
  },
  {
    nombre:'Jose Anta Álvarez',
    centro:'UDC'
  },
	{
    nombre:'Ernest Bladé I Castellet',
    centro:'UPC'
  },
  {
    nombre:'Francisco Cabezas Calvo-Rubio',
    centro:'IEA'
  },
  {
    nombre:'Óscar Castro Orgaz',
    centro:'UCO'
  },
  {
    nombre:'Luis Cea Gómez ',
    centro:'UDC'
  },
	{
    nombre:'Félix Francés García',
    centro:'UPV'
  },
  {
    nombre:'Luis Garrote de Marcos',
    centro:'UPM'
  },
  {
    nombre:'Manuel Gómez Valentín',
    centro:'UPC', 
    img:'assets/otros/RIP.svg'
  },
  {
    nombre:'Sonsoles González González',
    centro:'HGM'
  },
  {
    nombre:'Javier González Pérez',
    centro:'UCLM'
  },
  {
    nombre:'David López Gómez',
    centro:'CEDEX'
  },
  {
    nombre:'Elena Martínez Bravo ',
    centro:'HGM'
  },
  {
    nombre:'Rafael Morales Baquero',
    centro:'UGR'
  },
  {
    nombre:'Juan Ojeda Cochoud',
    centro:'Typsa'
  },
	{
    nombre:'Inmaculada Pulido Calvo',
    centro:'UHU'
  },
  {
    nombre:'Leonor Rodríguez Sinobas',
    centro:'UPM'
  },
  {
    nombre:'Pablo Roa Prieto',
    centro:'MCT'
  },
  {
    nombre:'Beniamino Russo',
    centro:'EUPLA'
  },
  {
    nombre:'Miguel Ángel Toledo Municio',
    centro:'UPM'
  },

  {
    nombre:'Francisco José Vallés Morán',
    centro:'UPV'
  }
  

  ];
  export default comite_organizador_cientifico_vocales