const Data = [
  {
    key: 1,
    titulo: "EQUIPMENT FOR THE ESTIMATION OF THE POLLUTION LOAD OF SEWAGE SYSTEMS FROM SPECTROPHOTOMETRIC ANALYSIS",
    autores:'Prof. Dr. Anton Schleiss',
    institucion:'Ecole Polytechnique Fédérale de Lausanne (EPFL) Professor emeritus and expert consultant',
    descripcion:'Algun texto interesante',   
    foto_poster:'assets/posters/Poster1.png',
  },
  {
    key: 2,
    titulo: "EQUIPMENT FOR THE ESTIMATION OF THE POLLUTION LOAD OF SEWAGE SYSTEMS FROM SPECTROPHOTOMETRIC ANALYSIS",
    autores:'Prof. Dr. Anton Schleiss',
    institucion:'Ecole Polytechnique Fédérale de Lausanne (EPFL) Professor emeritus and expert consultant',
    descripcion:'Algun texto interesante', 
    foto_poster:'assets/posters/Poster1.png',
  
  },
  {
    key: 3,
    titulo: "EQUIPMENT FOR THE ESTIMATION OF THE POLLUTION LOAD OF SEWAGE SYSTEMS FROM SPECTROPHOTOMETRIC ANALYSIS",
    autores:'Prof. Dr. Anton Schleiss',
    institucion:'Ecole Polytechnique Fédérale de Lausanne (EPFL) Professor emeritus and expert consultant',
    descripcion:'Algun texto interesante',   
    foto_poster:'assets/posters/Poster1.png',
  },
  {
    key: 4,
    titulo: "EQUIPMENT FOR THE ESTIMATION OF THE POLLUTION LOAD OF SEWAGE SYSTEMS FROM SPECTROPHOTOMETRIC ANALYSIS",
    autores:'Prof. Dr. Anton Schleiss',
    institucion:'Ecole Polytechnique Fédérale de Lausanne (EPFL) Professor emeritus and expert consultant',
    descripcion:'Algun texto interesante',   
    foto_poster:'assets/posters/Poster1.png',

  },
  {
    key: 5,
    titulo: "EQUIPMENT FOR THE ESTIMATION OF THE POLLUTION LOAD OF SEWAGE SYSTEMS FROM SPECTROPHOTOMETRIC ANALYSIS",
    autores:'Prof. Dr. Anton Schleiss',
    institucion:'Ecole Polytechnique Fédérale de Lausanne (EPFL) Professor emeritus and expert consultant',
    descripcion:'Algun texto interesante',   
    foto_poster:'assets/posters/Poster1.png',

  },
  {
    key: 6,
    titulo: "EQUIPMENT FOR THE ESTIMATION OF THE POLLUTION LOAD OF SEWAGE SYSTEMS FROM SPECTROPHOTOMETRIC ANALYSIS",
    autores:'Prof. Dr. Anton Schleiss',
    institucion:'Ecole Polytechnique Fédérale de Lausanne (EPFL) Professor emeritus and expert consultant',
    descripcion:'Algun texto interesante',   
    foto_poster:'assets/posters/Poster1.png',

  },
  {
    key: 7,
    titulo: "EQUIPMENT FOR THE ESTIMATION OF THE POLLUTION LOAD OF SEWAGE SYSTEMS FROM SPECTROPHOTOMETRIC ANALYSIS",
    autores:'Prof. Dr. Anton Schleiss',
    institucion:'Ecole Polytechnique Fédérale de Lausanne (EPFL) Professor emeritus and expert consultant',
    descripcion:'Algun texto interesante',   
    foto_poster:'assets/posters/Poster1.png',

  },
  
  
 
];
export default Data