import React from "react"
import { useEffect,useState  } from "react";
import { Carousel, Modal } from 'antd';
import { FaMapMarkerAlt, FaCalendarCheck, FaPlaceOfWorship } from "react-icons/fa";



import './Sede.css';
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const Pages = () => {
  useEffect(() => window.scrollTo(0, 0));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };




   return (
    
    <>
       <section className='Seccion_white' style={{marginTop:'-60px'}}>
      <div className='container'>
          <div className='heading'>
            <h1>Campus Muralla del Mar de la UPCT. Antiguo Hospital Militar de Marina</h1>
          </div>
        
            
            
            <div className="grid_sede">
                <div className="carrusel">
                        <Carousel autoplay style={{width:'100%', height:'100%'}}>
                              <div>
                              <img src='/assets/logistica/sede/1.jpg' style={{width:'100%', height:'410px',borderRadius:'10px'}} alt=""/>
                              </div>
                              <div>
                              <img src='/assets/logistica/sede/2.jpg' style={{width:'100%', height:'410px', borderRadius:'10px'}} alt=""/>
                              </div>
                              <div>
                              <img src='/assets/logistica/sede/3.jpg' style={{width:'100%', height:'410px', borderRadius:'10px'}} alt=""/>
                              </div>
                           
                        </Carousel>

                     </div>


                    <div >
                    <div style={{ marginTop:'20px',width:'70%', display:'flex', flexDirection:'row',  justifyContent: 'center', alignItems: 'center', margin:'auto'}}>
                            <img style={{height: '230px'  }}  src='assets/logojia2023.png' alt='' />
                    </div>
                    <div style={{ marginTop:'20px', width:'85%', display:'flex', flexDirection:'row',  justifyContent: 'center', alignItems: 'center', margin:'auto'}}>
                    <a href="https://www.google.es/maps/place/UPCT+Campus+Muralla+del+Mar/@37.6018988,-0.9815175,17z/data=!4m9!1m2!2m1!1sCampus+Muralla+del+Mar+de+la+UPCT.+Antiguo+Hospital+Militar+de+Marina!3m5!1s0xd63418635cb2fd1:0xe065ca76657175df!8m2!3d37.6004078!4d-0.979207!15sCkVDYW1wdXMgTXVyYWxsYSBkZWwgTWFyIGRlIGxhIFVQQ1QuIEFudGlndW8gSG9zcGl0YWwgTWlsaXRhciBkZSBNYXJpbmFaRiJEY2FtcHVzIG11cmFsbGEgZGVsIG1hciBkZSBsYSB1cGN0IGFudGlndW8gaG9zcGl0YWwgbWlsaXRhciBkZSBtYXJpbmGSAQp1bml2ZXJzaXR5mgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVU5wTkdSVGExQkJFQUXgAQA?hl=es" target="_blank" rel="noreferrer">

                        <div className="cad_con_texto">
                          <div className = 'Card_opciones_sede'>
                            <FaMapMarkerAlt style={{fontSize: '30px'}} alt=""/>
                          </div>
                          <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.9rem', marginTop:'-10px'}}>Cómo llegar</h2>
                        </div>
                        </a>
                        <div style={{backgroundColor:'#3734362e', width:'1px', height:'40px',justifyContent: 'center',margin: 'auto', alignItems: 'center'}}></div>

                        <div className="cad_con_texto">
                          <div className = 'Card_opciones_sede'>
                            <FaCalendarCheck style={{fontSize: '30px'}} alt=""/>
                          </div>
                          <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.9rem', marginTop:'-10px'}}>Calendario</h2>
                        </div>
                        <div style={{backgroundColor:'#3734362e', width:'1px', height:'40px',justifyContent: 'center',margin: 'auto', alignItems: 'center'}}></div>

                        <div className="cad_con_texto">
                          <div className = 'Card_opciones_sede'>
                            <FaPlaceOfWorship style={{fontSize: '30px'}} alt=""/>
                          </div>
                          <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'0.9rem',  marginTop:'-10px'}}>Sobre la sede</h2>
                        </div>
                      </div>
                      
                    </div>
                </div>
            </div>
          
            
          
      </section>

      
    </>
  )
}

export default Pages
