import React from "react"
import { Link } from "react-router-dom"
import styles from "../../../Home/Elementos/VideoHeader/videoheader.module.css";


const Envio_Resumen_Comunicacion = () => {
  
  return (
    <>
      <section >
          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left', margin:'20px'}}>A)	Envío de resúmenes y comunicaciones</h3>
          <h1 style={{margin:'20px 0px 20px 30px', color:'#1886e6'}}>Envío de resúmenes</h1>
          <div className='container'>
            <div style={{margin:'0px 0px 0px 0px'}}>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Los resúmenes deberán enviarse <b>antes del 14 de Abril de 2023.</b>
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Los resúmenes deberán enviarse en formato .docx y pdf ajustándose al formato e instrucciones en cuanto a la extensión y formatos adjuntas (<a href="/assets/docs/PlantillaResumenJIA2023.docx" target="_blank" rel="noreferrer"><b>Descargar plantilla resumen</b></a>). El envío de resúmenes se realizará a través de la propia página web del congreso que está asociada a una plataforma de gestión de resúmenes y comunicaciones para la cual se deberá disponer de un usuario y contraseña, y acceder mediante el siguiente botón:
                </p>
                <div style={{display:'flex',  alignContent:'center',alignItems:'center', justifyContent: 'center', marginBottom:'20px'}}>
                    <a href="https://easychair.org/conferences/?conf=viijia2023" target="_blank" rel="noopener noreferrer">
                          <button className={styles.primary_btn}> ENVÍO RESÚMENES</button>
                    </a>
                </div>
                
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El Comité Científico hará una valoración y notificará la aceptación de resúmenes y las becas para la inscripción de doctorandos <b>antes del 15 de mayo de 2023.</b>
                </p>
            </div>          
          </div>      
        
          <h1 style={{margin:'0px 0px 20px 30px', color:'#1886e6'}}>Envío de comunicaciones</h1>
          <div className='container'>
            <div style={{margin:'0px 0px 0px 0px'}}>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  En caso de aceptación del trabajo, <b>antes del 14 de Julio de 2023</b> se deberán enviar los textos completos de las comunicaciones se enviarán en formato .docx y .pdf. Todos los trabajos aceptados, ya sea como presentación oral o póster, serán incluidos sin distinción en las Actas de las Jornadas, que se publicarán en formato digital. El formato e instrucciones para las comunicaciones pueden ser descargadas del siguiente enlace: (<a href="/assets/docs/Plantilla_comunicaciones_JIA2023.docx" target="_blank" rel="noreferrer"> <b>Descargar instrucciones de comunicación</b></a>). El envío de comunicaciones se realizará a través de la propia página web del congreso que está asociada a una plataforma de gestión de resúmenes y comunicaciones para la cual se deberá disponer de un usuario y contraseña.
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                <b>La aceptación de las comunicaciones para su publicación y presentación</b> (oral o póster) está condicionada a recibir el <b>texto completo</b> y a <b>abonar los derechos de inscripción</b> por parte de uno de los autores de la comunicación <b>antes del 25 de julio de 2023</b>. Cada comunicación presentada, debe llevar asociada al menos una inscripción. Cada inscrito, podrá presentar oralmente un máximo de dos comunicaciones al congreso, no existiendo límite en el número de comunicaciones en las que se puede aparecer como autor. El idioma oficial de las jornadas JIA 2023 es el castellano.
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El comité científico recomendará un número limitado de comunicaciones para su publicación en la revista <a style={{textDecoration:'underline'}} href="http://polipapers.upv.es/index.php/IA/index" target="_blank">Ingeniería del Agua</a> y <a style={{textDecoration:'underline'}} href="http://www.elsevier.es/es-revista-ribagua-revista-iberoamericana-del-217" target="_blank">RIBAGUA</a>. Los autores serán invitados a preparar una versión adicional del artículo para dichas revistas (que pasará un proceso de revisión adicional).
                </p>
            </div>          
          </div>  
      </section>
    </>
  )
}

export default Envio_Resumen_Comunicacion
