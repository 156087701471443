import React from "react"
import { useEffect } from "react";
import './estilos.css'


const Monografico = () => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Premios</h3>
            <h1>Red de laboratorios de Hidráulica de España (RLHE) a la mejor comunicación de carácter experimental vinculada a una tesis doctoral</h1>
          </div>
          <div style={{margin:'0px 0px 20px 0px'}}>
                      
            <div style={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         margin:'auto',
                         marginBottom:'20px'
                         }}>

                   <div>   
                      <div style={{width:'100%', padding:'20px', backgroundColor:'#FFFF'}}>
                        <p style={{textAlign:'justify', fontSize:'1rem'}}>
                          <b>1.</b> Podrán presentarse al Premio todos los doctorandos que presenten una comunicación como primer autor (debiendo figurar además como co-autor el Director de la Tesis) a las Jornadas de Ingeniería del Agua JIA 2023 sobre hidráulica experimental (laboratorio y/o campo).
                        </p>
                      </div>

                      <div style={{width:'100%', padding:'20px', backgroundColor:'#f8f9fe'}}>
                        <p style={{textAlign:'justify', fontSize:'1rem'}}>
                          <b>2.</b> Los interesados en concursar al premio deberán remitir la siguiente documentación:
                        </p>
                        <ul className='ul_premio'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Texto de la comunicación a las JIA 2023, en los plazos y forma indicados en las instrucciones de las Jornadas JIA 2023 que figuran en la web.
                          </li>
                        </ul>
                        <ul className='ul_premio'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Currículum vitae breve (máx 2 páginas).
                          </li>
                        </ul>
                      </div>

                      <div style={{width:'100%', padding:'20px', backgroundColor:'#FFFF'}}>
                        <p style={{textAlign:'justify', fontSize:'1rem'}}>
                          <b>3.</b> La selección de la comunicación premiada se le encomienda al Comité Científico de las JIA 2023, cuyo fallo será inapelable.
                        </p>
                      </div> 

                    <div style={{width:'100%', padding:'20px', backgroundColor:'#f8f9fe'}}>
                        <p style={{textAlign:'justify', fontSize:'1rem'}}>
                          <b>4.</b> El Comité Científico seleccionará la premiada entre las comunicaciones que reúnan las condiciones anteriores a partir de la valoración de los aspectos siguientes:
                        </p>
                        <ul className='ul_premio'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Vinculación de la comunicación con las Líneas Prioritarias de la Red de Laboratorios de Hidráulica de España
                          </li>
                        </ul>
                        <ul className='ul_premio'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Aplicabilidad práctica de la tesis doctoral en la que se enmarca la comunicación presentada.
                          </li>
                        </ul>
                        <ul className='ul_premio'>
                          <li style={{textAlign: 'left', fontSize:'1rem'}}>
                            Currículum vitae del doctorando. Se valorará en particular la experiencia en publicaciones, comunicaciones y ponencias presentadas en congresos, seminarios y simposios nacionales e internacionales.
                          </li>
                        </ul>
                      </div>

                      <div style={{width:'100%', padding:'20px', backgroundColor:'#FFFF'}}>
                        <p style={{textAlign:'justify', fontSize:'1rem'}}>
                          <b>5.</b> El fallo del Comité Científico se realizará antes del 31 de julio de 2023.
                        </p>
                      </div> 

                      <div style={{width:'100%', padding:'20px', backgroundColor:'#f8f9fe'}}>
                        <p style={{textAlign:'justify', fontSize:'1rem'}}>
                          <b>6.</b> La dotación del premio será la inscripción (o devolución de la cuota de inscripción) y los gastos de viaje (transporte y alojamiento) para asistir a las Jornadas JIA 2023 que se celebrarán en Cartagena en octubre de 2023. La cuantía máxima del premio serán 1000 €.
                        </p>
                      </div> 

                      <div style={{width:'100%', padding:'20px', backgroundColor:'#ffff'}}>
                        <p style={{textAlign:'justify', fontSize:'1rem'}}>
                          <b>7.</b> El premio será presentado por el Presidente del Comité Científico y el Director de la Red de Laboratorios de Hidráulica de España en la sesión inaugural de las JIA 2023 y se entregará en un acto posterior a la sesión de pósteres.
                        </p>
                      </div>
                  
                      <div style={{width:'100%', padding:'20px', backgroundColor:'#f8f9fe'}}>
                        <p style={{textAlign:'justify', fontSize:'1rem'}}>
                          <b>8.</b> Cualquier comunicación sobre este Premio se realizará a través de la dirección de correo electrónico <a style={{textDecoration:'underline'}} href='mailto:ce-rlhe@iahr.org'>ce-rlhe@iahr.org</a>.
                        </p>
                      </div>

                  </div>
            </div>

          </div>          
          </div>      
        
      </section>
    </>
  )
}

export default Monografico
