import React from "react"
import { useEffect } from "react";
import Card from './Card'
import Data from "./Data"

const Ponencias = () => {
  useEffect(() => window.scrollTo(0, 0));
  

   return (
    
    <>
       <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Ponencias invitadas</h3>
            <h1>Conoce a nuestros conferenciantes</h1>
          </div>
          <div style={{
            alignItems: 'center',
            justifyContent: 'center',
            display:'flex'
          }}>
               <div className='card_grid4'>
              {Data.map((value) => {
                return (
                <Card nombre={value.nombre} puesto={value.puesto} foto={value.foto} bio={value.bio} foto_background={value.foto_background} />
                )
              })}
            </div>
          </div>
          


      
          
        </div>      
        
      </section>
    </>
  )
}

export default Ponencias
