import React from "react"
import { ClockCircleOutlined,SendOutlined,NotificationOutlined,ScheduleOutlined,StarOutlined  } from '@ant-design/icons';
import { FaAward } from "react-icons/fa";

import { ImFileEmpty,ImFileText2 } from "react-icons/im";
import { AiFillSchedule } from "react-icons/ai";
import { Link } from "react-router-dom"

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';


import comite_permanente_copresidente from "./miembros/Data_comite_permanente_copresidente";
import comite_permanente_vocales from "./miembros/Data_comite_permanente_vocales";

import comite_organizador_local_Presidente from "./miembros/Data_comite_organizador_local_Presidente";
import comite_organizador_local_Secretario  from "./miembros/Data_comite_organizador_local_Secretario";
import comite_organizador_local_vocales  from "./miembros/Data_comite_organizador_local_vocales";


import comite_organizador_cientifico_Presidente  from "./miembros/Data_comite_organizador_cientifico_Presidente";
import comite_organizador_cientifico_Secretario  from "./miembros/Data_comite_organizador_cientifico_Secretario";
import comite_organizador_cientifico_vocales  from "./miembros/Data_comite_organizador_cientifico_vocales";

import { useEffect } from "react";
import './oganizacion.css'
import DataColaboradores from "./DataColaboradores"



const Organizacion = () => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Organizacion</h3>
          </div>
          <div style={{margin:'0px 0px 20px 0px'}}>
                      
            <div style={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         marginTop:'0px',
                         marginBottom:'20px'
                         }}>
                        <p style={{textAlign:'left', fontSize:'1rem'}}>
                          La organización de las VII JIA corre a cargo del Grupo de Ingeniería Hidráulica, Marítima y Medioambiental (HIDR@M), con base en la Escuela Técnica Superior de Ingeniería de Caminos, Canales y Puertos y de Ingeniería de Minas de la Universidad Politécnica de Cartagena.                       
                        </p> 
            </div>

          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div className='grid_organizacion'>
              <div>
                <h2 style={{fontWeight:'700', fontSize:'1.5rem', marginTop:'0px', color:'#3896d1'}}>Comité Permanente</h2>
                        <div style={{flex:'auto' , height:'90px'}}>
                          <h2 style={{textAlign:'left', fontWeight:'700', fontSize:'1.2rem', color:'#3896d1'}}>Co-presidentes</h2>
                          {comite_permanente_copresidente.map((value) => {
                            return (
                              <p style={{textAlign:'left', fontSize:'1rem', color:'#1f1d1dd1', marginLeft:'20px'}}>
                                - {value.nombre} ({value.centro}) {value.img ? <img style={{width:'12px'}} src={value.img} alt="Imagen" /> : null}
                              </p>

                              )
                          })}
                        </div>
                        
                        <div style={{flex:'auto' , height:'90px'}}>
                          
                        </div>

                          <div style={{flex:'auto'}}>
                          <h2 style={{textAlign:'left', fontWeight:'700', fontSize:'1.2rem', color:'#3896d1'}}>Vocales</h2>
                          {comite_permanente_vocales.map((value) => {
                            return (
                              <p style={{textAlign:'left', fontSize:'1rem', color:'#1f1d1dd1', marginLeft:'20px'}}>
                                - {value.nombre} ({value.centro}) {value.img ? <img style={{width:'12px'}} src={value.img} alt="Imagen" /> : null}
                              </p>
                          )
                          })}
                      </div>
              </div>

              <div>
              <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'1.5rem', marginTop:'0px', color:'#3896d1'}}>Comité Organizador Local</h2>
                      <div style={{flex:'auto' , height:'90px'}}>
                        <h2 style={{textAlign:'left', fontWeight:'700', fontSize:'1.2rem', color:'#3896d1'}}>Presidentes</h2>
                        {comite_organizador_local_Presidente.map((value) => {
                          return (
                            <p style={{textAlign:'left', fontSize:'1rem', color:'#1f1d1dd1', marginLeft:'20px'}}>
                              - {value.nombre} ({value.centro}) {value.img ? <img style={{width:'12px'}} src={value.img} alt="Imagen" /> : null}
                            </p>
                            )
                        })}
                      </div>
                      
                      <div style={{flex:'auto' , height:'90px'}}>
                        <h2 style={{textAlign:'left', fontWeight:'700', fontSize:'1.2rem', color:'#3896d1'}}>Secretario</h2>
                            {comite_organizador_local_Secretario.map((value) => {
                              return (
                                <p style={{textAlign:'left', fontSize:'1rem', color:'#1f1d1dd1', marginLeft:'20px'}}>
                                  - {value.nombre} ({value.centro}) {value.img ? <img style={{width:'12px'}} src={value.img} alt="Imagen" /> : null}
                                </p>
                                )
                            })}
                      </div>

                        <div style={{flex:'auto'}}>
                          <h2 style={{textAlign:'left', fontWeight:'700', fontSize:'1.2rem', color:'#3896d1'}}>Vocales</h2>
                          {comite_organizador_local_vocales.map((value) => {
                            return (
                              <p style={{textAlign:'left', fontSize:'1rem', color:'#1f1d1dd1', marginLeft:'20px'}}>
                                - {value.nombre} ({value.centro}) {value.img ? <img style={{width:'12px'}} src={value.img} alt="Imagen" /> : null}
                              </p>
                              )
                          })}
                      </div>  
              </div>

              <div>
              <h2 style={{textAlign:'center', fontWeight:'700', fontSize:'1.5rem', marginTop:'0px', color:'#3896d1'}}>Comité Científico</h2>
                      <div style={{flex:'auto' , height:'90px'}}>
                        <h2 style={{textAlign:'left', fontWeight:'700', fontSize:'1.2rem', color:'#3896d1'}}>Presidentes</h2>
                        {comite_organizador_cientifico_Presidente.map((value) => {
                          return (
                            <p style={{textAlign:'left', fontSize:'1rem', color:'#1f1d1dd1', marginLeft:'20px'}}>
                                - {value.nombre} ({value.centro}) {value.img ? <img style={{width:'12px'}} src={value.img} alt="Imagen" /> : null}
                            </p>
                          )
                        })}
                      </div>
                      
                      <div style={{flex:'auto' , height:'90px'}}>
                        <h2 style={{textAlign:'left', fontWeight:'700', fontSize:'1.2rem', color:'#3896d1'}}>Secretario</h2>
                            {comite_organizador_cientifico_Secretario.map((value) => {
                              return (
                                <p style={{textAlign:'left', fontSize:'1rem', color:'#1f1d1dd1', marginLeft:'20px'}}>
                                  - {value.nombre} ({value.centro}) {value.img ? <img style={{width:'12px'}} src={value.img} alt="Imagen" /> : null}
                                </p>
                                )
                            })}
                      </div>

                        <div style={{flex:'auto'}}>
                          <h2 style={{textAlign:'left', fontWeight:'700', fontSize:'1.2rem', color:'#3896d1'}}>Vocales</h2>
                          {comite_organizador_cientifico_vocales.map((value) => {
                            return (
                              <p style={{textAlign:'left', fontSize:'1rem', color:'#1f1d1dd1', marginLeft:'20px'}}>
                                  - {value.nombre} ({value.centro}) {value.img ? <img style={{width:'12px'}} src={value.img} alt="Imagen" /> : null}
                              </p>
                              )
                          })}
                      </div>  
              </div>

          </div>
             
          </div>
          <div className='heading'>
            <h3>Colaboradores</h3>
          </div>
          <div style={{margin:'0px 0px 20px 0px'}}>                      
            <div style={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         marginTop:'0px',
                         marginBottom:'20px'
                         }}>
            <div className='grid_colaboradores'>
                {DataColaboradores.map((value) => {
                  return (
                    <div style={{margin:'auto', alignContent:'center', alignItems:'center'}}>
                      <Tooltip title={value.nombre} TransitionComponent={Zoom}>        
                        <a href={value.url} target="_blank" rel="noreferrer">        
                          <img className="colaboradores" style={{height: value.height}}  src={value.logo} alt='' />  
                        </a>           
                      </Tooltip>
                    </div>
                    )
                })}
            </div>

            </div>   
            </div>   
           </div>      
        
      </section>
    </>
  )
}

export default Organizacion
