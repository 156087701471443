import React from "react"
import { Link } from "react-router-dom"


const Avion = () => {
  
  return (
    <>
      <section style={{backgroundColor:'#ffff', padding:'20px'}}>
          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left'}}>Avión</h3>
          <div className='container'>
            <div style={{margin:'0px 0px 0px 0px'}}>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  <b>Aeropuerto de Alicante-Elche (ALC)</b> situado a 117 km de Cartagena. Vuelos directos con la mayoría de las provincias nacionales.
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El desplazamiento hasta Cartagena desde el aeropuerto se podrá realizar mediante autobús de la compañía <b>BAM</b> (En el siguiente enlace, puede <a style={{textDecoration:'underline'}} href='https://www.busbam.com/horarios/cartagena-alicante/' target="_blank">Consultar horarios</a>) o mediante servicio de taxi.
                </p>
           
            </div>          
          </div>  
      </section>
    </>
  )
}

export default Avion
