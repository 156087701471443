import React, {useState} from 'react'
import { Button, Modal } from 'antd';

import PropTypes from "prop-types";

import './Card.css'
function Card( {nombre, puesto, bio, foto, foto_background}){

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return(
        <div className = 'Card'>
                <div className = 'upper-container' 
                    style={{
                        backgroundImage: `url(${foto_background})`, 
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize:'cover',
                        borderRadius: '10px 10px 0px 0px'
                    }}>
                
               

                    <div className='image-container'>
                        <img src={foto} alt='' height='100px' width='100px'/>
                    </div>
                </div>
                <div className ='lower-container'>
                    <div style={{height:'150px', width:'95%'}}>
                        <h3> {nombre} </h3>
                        <h4> {puesto} </h4>
                    </div>
                    
                    <div style={{backgroundColor:'#3734362e', width:'80%', height:'1px',justifyContent: 'center',margin: 'auto', alignItems: 'center'}}></div>
                    <div style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop:'10px',
                                }}>
                    <button className='primary_btn' style={{marginRight:'20px'}} onClick={showModal}> Ver Pefil</button>
                    <button className='primary_btn'> Ponencia</button>

                    </div>
                </div>



                <Modal title={nombre}open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={'90%'}>
                        {bio.map((value) => {
                            return (
                                <div>
                                    <p style={{marginBottom:'20px', textAlign:'justify', fontSize:'1rem'}}>{value.text}</p>
                                </div>
                                
                            )
                        })}
                   
                  
                </Modal>
        </div>
        
    )

}
Card.propTypes = {
    nombre: PropTypes.string.isRequired,
    puesto: PropTypes.string,
    bio: PropTypes.string,
    foto: PropTypes.string,
    foto_background: PropTypes.string,
  };
export default Card