import React from "react"
import { Link } from "react-router-dom"


const Autobus_Coche = () => {
  
  return (
    <>
      <section style={{backgroundColor:'#ffff', padding:'20px'}}>
          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left'}}>En autobús o coche</h3>
          <div className='container'>
            <div style={{margin:'0px 0px 0px 0px'}}>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  La estación de autobuses tiene servicios con numerosas capitales de provincia. Rutas a Cartagena Madrid y Barcelona a través de la compañía ALSA.
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Más información en: <a style={{textDecoration:'underline'}} href='https://www.alsa.es/destino' target="_blank">https://www.alsa.es/destino</a>
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Las autopistas A30 desde Madrid, la A7-AP7, Autovía de los Viñedos (CM-42), así como la A92N desde Andalucía enlazan Cartagena con el Norte, Oeste y Sur de España.
                </p>
           
                
            </div>          
          </div>  
      </section>
    </>
  )
}

export default Autobus_Coche
