import { fontWeight } from "@mui/system";
import React from "react"
import { useEffect } from "react";
import Card from './Card'
import Data from "./Data"

const Pages = () => {
  useEffect(() => window.scrollTo(0, 0));
  

   return (
    
    <>
       <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Inscripcion</h3>
          </div>

          <div style={{margin:'0px 0px 20px 0px'}}>
            <p style={{textAlign:'justify', fontSize:'1rem'}}>
              El congreso ofrece un descuento en la inscripción (50%) a aquellos doctorandos matriculados en un programa de doctorado relacionado con la ingeniería del agua que presenten una comunicación como primer autor que exponga avances en su tesis (debiendo figurar además como co-autores el director/es de la tesis). A la hora de realizar el envío del Resumen (antes del 14 de Abril de 2023), los estudiantes deben indicar esta circunstancia en el formulario de envío.               La organización notificará a cada congresista un código de descuento que deben incluir a la hora de hacer la inscripción.
            </p>
            <p style={{textAlign:'justify',  fontSize:'1rem', marginTop:'5px'}}>
            Una inscripción da derecho a la presentación de un máximo de dos comunicaciones
            </p>
          </div>
          
          <div style={{
            alignItems: 'center',
            justifyContent: 'center',
            
          }}>
               <div className='card_grid_inscripcion'>
             
               {Data.map((value) => {
                return (
                    <Card plan={value.plan} precio={value.precio} puntos={value.puntos} gradiente1={value.gradiente1} gradiente2={value.gradiente2} foto_background={value.foto_background} />
                )
              })}
            </div>
          </div>
          <div style={{margin:'30px'}}>
            <p style={{textAlign:'justify',  fontSize:'1rem'}}>
            (*) La fecha límite de inscripción para que los artículos aparezcan en el libro de resúmenes del congreso es el 14 de Julio de 2023.            
            </p>
            <p style={{textAlign:'justify',  fontSize:'1rem'}}>
            (**) La fecha limite para las inscripciones con carácter general será el 15 de octubre de 2023.            
            </p>
           
          </div>


      
          
        </div>      
        
      </section>
    </>
  )
}

export default Pages
