import React from "react"
import { useEffect } from "react";
import Card from './Card'
import Data from "./Data"

const Lineas_Tematicas = () => {
  useEffect(() => window.scrollTo(0, 0));
  

   return (
    
    <>
       <section className='Seccion_grey'>
      <div className='container'>
          <div className='heading'>
            <h3>Líneas temáticas</h3>
            <h1>y su relación con los Objetivos de Desarrollo Sostenible (ODS 2030)</h1>
          </div>
          <div style={{
            alignItems: 'center',
            justifyContent: 'center'
          }}>
               <div className='card_grid_ODS'>
              {Data.map((value) => {
                return (
                    <Card linea={value.linea} linea2={value.linea2}letra={value.letra} puntos={value.puntos} ods={value.ods} foto_background={value.foto_background} />
                )
              })}
            </div>
          </div>
          


      
          
        </div>      
        
      </section>
    </>
  )
}

export default Lineas_Tematicas
