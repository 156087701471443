const comite_permanente_copresidente = [
  
    {
      nombre:'Luis Balairón Pérez ',
      centro:'CEDEX'
    },
    {
      nombre:'José Dolz Ripollés ',
      centro:'UPC'
    },

  ];
  export default comite_permanente_copresidente