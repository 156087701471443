import React from "react"
import { ClockCircleOutlined,SendOutlined,NotificationOutlined,ScheduleOutlined,StarOutlined  } from '@ant-design/icons';
import { FaAward } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';
import { ImFileEmpty,ImFileText2 } from "react-icons/im";
import { AiFillSchedule } from "react-icons/ai";
import { Link } from "react-router-dom"
import styles from "./eventos.module.css";
import { Timeline } from 'antd';
import { useEffect } from "react";

const Eventos = () => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Fechas Clave</h3>
            <h1>Eventos relevantes de la JIA 2023</h1>
          </div>
          <div style={{margin:'0px 0px 20px 0px'}}>
            <p style={{textAlign:'left', fontSize:'1rem'}}>
                Las VII Jornadas de Ingeniería del Agua se celebran los días 18 y 19 de octubre de 2023 en el Campus Muralla del Mar, Calle Dr. Fleming, s/n, Cartagena, España. El día 17 hay prevista una jornada de actividades previas a las JIA.
            </p>
            
            <div style={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         marginTop:'20px',
                         marginBottom:'50px'
                         }}>
                              <button className={styles.primary_btn}>
                                  <Link to="/assets/docs/Programa detallado VII JIA.pdf" target="_blank" style={{color:'white'}} >DESCARGAR PROGRAMA JIA 2023</Link>


                              </button>
                          
            </div>

          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
             <Timeline mode="alternate">
                
                <Timeline.Item className={styles.evento} dot={<ImFileEmpty style={{ fontSize: '25px' }} />}>
                  <div style={{margin:'10px 10px 10px 10px'}}>
                        <p style={{textAlign:'justify', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}> 14 de Abril 2023</p>
                        <div>
                          <p style={{textAlign:'justify', fontWeight:'700',fontSize:'0.9rem',color:'#2d2a2aab'}}> 
                            Envío de resúmenes y petición de becas para la inscripción de doctorandos <br></br>(<Link style={{textDecoration:'underline'}} to='/autores'>'Ver Instrucciones'</Link>)
                          </p>                         
                        </div>
                  </div>
                 
                  
                </Timeline.Item>

                <Timeline.Item className={styles.evento} dot={<NotificationOutlined  style={{ fontSize: '25px' }} />}>
                  <div style={{margin:'10px'}}>
                      <p style={{textAlign:'right', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}> 15 de Mayo 2023</p>
                      <div>
                      <p style={{textAlign:'justify', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                        Notificación de la aceptación de resúmenes y notificación de becas para la inscripción de doctorandos<br></br>   
                        (<Link style={{textDecoration:'underline'}} to='/autores'>'Ver Instrucciones'</Link>)
                      </p>
                      
                      </div>
                  </div>
                </Timeline.Item>

                <Timeline.Item className={styles.evento} dot={<ScheduleOutlined style={{ fontSize: '25px' }} />}>
                  <div style={{margin:'10px'}}>
                      <p style={{textAlign:'justify', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}> 14 de Junio 2023</p>
                      <div>
                      <p style={{textAlign:'justify', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                          Fecha tope para la inscripción con importe reducido (<Link  style={{textDecoration:'underline'}} to='/inscripcion'>'Ver inscripción'</Link>)
                      </p>
                      </div>
                  </div>
                  
                </Timeline.Item>

                <Timeline.Item className={styles.evento} dot={<ImFileText2 style={{ fontSize: '25px' }} />}>
                  <div style={{margin:'10px'}}>
                      <p style={{textAlign:'right', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}> 14 de Julio 2023</p>
                      <div>
                      <p style={{textAlign:'right', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                          Envío del texto completo, para su publicación
                      </p>
                      </div>
                  </div>
                    
                </Timeline.Item>

                <Timeline.Item className={styles.evento} dot={<FaAward style={{ fontSize: '25px' }} />}>
                  <div style={{margin:'10px'}}>
                      <p style={{textAlign:'justify', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}> 31 de Julio 2023</p>
                      <div>
                      <p style={{textAlign:'justify', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                        Fallo del Comité Científico sobre la ponencia premiada
                      </p>
                      </div>
                  </div>
                     
                </Timeline.Item>

                <Timeline.Item className={styles.evento} dot={<ScheduleOutlined style={{ fontSize: '25px' }} />}>
                <div style={{margin:'10px'}}>
                      <p style={{textAlign:'right', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}> 15 de Septiembre 2023</p>
                      <div>
                        <p style={{textAlign:'right', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                            Inicio de la inscripción tardía
                        </p>
                      </div>
                  </div>                      
                </Timeline.Item>

                <Timeline.Item className={styles.evento} dot={<ScheduleOutlined style={{ fontSize: '25px' }} />}>
                <div style={{margin:'10px'}}>
                      <p style={{textAlign:'justify', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}> 22 de Septiembre 2023</p>
                      <div>
                        <p style={{textAlign:'justify', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                          Fecha tope para la inscripción en los cursos
                        </p>
                      </div>
                  </div>
                </Timeline.Item>

                <Timeline.Item className={styles.evento} dot={<ScheduleOutlined style={{ fontSize: '25px' }} />}>
                <div style={{margin:'10px'}}>
                      <p style={{textAlign:'right', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}>9 de Octubre 2023</p>
                      <div>
                        <p style={{textAlign:'right', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                          Fecha tope para la inscripción en las VII JIA
                        </p>
                      </div>
                  </div>
                </Timeline.Item>

                <Timeline.Item className={styles.evento} dot={<ClockCircleOutlined style={{ fontSize: '25px' }} />}>
                <div style={{margin:'10px'}}>
                      <p style={{textAlign:'justify', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}>17 de Octubre 2023</p>
                      <div>
                        <p style={{textAlign:'justify', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                          -   Jornada de actividades previas a las VII JIA                     
                        </p>
                      </div>
                      <div>
                        <p style={{textAlign:'justify', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                            -   Celebracíon de concursos (<Link style={{textDecoration:'underline'}} to='/premios'>'Ir a Premios'</Link>)
                        </p>
                        
                      </div>                     
                  </div>                   
                  
                </Timeline.Item>
                
                <Timeline.Item className={styles.evento} dot={<StarOutlined style={{ fontSize: '25px' }} />}>
                <div style={{margin:'10px'}}>
                      <p style={{textAlign:'right', fontWeight:'700', fontSize:'1.05rem', color:'#1f1d1dd1'}}>18-19 de Octubre 2023</p>
                      <div>
                      <p style={{textAlign:'right', fontWeight:'700', fontSize:'0.9rem', color:'#2d2a2aab'}}> 
                        Celebración de las VII JIA
                      </p>
                      
                      </div>
                  </div>                    
                </Timeline.Item>


                
              </Timeline>
          </div>
           </div>      
        
      </section>
    </>
  )
}

export default Eventos
