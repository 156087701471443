const Data = [
  {
    id: 2,
    foto:'assets/ponentes/Matos.jpg',
    nombre: "Prof. Dr. Jorge Matos",
    puesto: "Instituto Superior Técnico, Universidade de Lisboa",
    foto_background:"/assets/ponentes/bg_Matos.jpg",
    dia:'18 de octubre',
    bio:[
      {
        text:'El profesor Jorge Matos trabaja en el Departamento de Ingeniería Civil, Arquitectura y Geo-recursos del Instituto Superior Técnico (IST), Universidad de Lisboa, Portugal.',
      },
      {
        text:'Además de la cátedra en el IST, también fue profesor invitado en la Academia Militar, así como en la Escuela de Tecnología Barreiro, del Instituto Politécnico de Setúbal, durante breves períodos.',
      },
      {
        text:'Sus intereses de investigación se centran principalmente en las estructuras hidráulicas, con especial atención a la hidráulica de vertederos, los flujos aire-agua y la modelización física.',
      },
      {
        text:'En el marco de su actividad de investigación/consultoría, Jorge ha sido miembro del Centro de Investigación en Hidrosistemas (CEHIDRO) del IST, y ha participado en esfuerzos de cooperación con grupos de investigación de varias universidades e instituciones.',
      },
      {
        text:'Ha recibido el Premio de la APRH - Asociación Portuguesa de Recursos Hídricos por su trabajo científico en el campo de los recursos hídricos, 2001/02, así como el Premio J. C. Stevens 2002, otorgado por el Instituto de Recursos Hídricos y Ambientales de la Sociedad Americana de Ingenieros Civiles. Ha participado en el Comité de Estructuras Hidráulicas de la IAHR desde su creación en 1998, concretamente como Secretario (2003-05), y Presidente (2005-09).',
      },
      {
        text:'Formó parte de la junta directiva de la APRH desde 2004, siendo Presidente del Comité Ejecutivo y de la Asamblea General en los bienios 2008-09 y 2010-11, respectivamente.',
      },
      {
        text:'Presidió la organización del 4º Simposio Internacional de Estructuras Hidráulicas de la AIAH y del 11º Congreso Nacional del Agua, ambos celebrados en Oporto, Portugal, en febrero de 2012.',
      },
    ],
  
  },
  {
    id: 3,
    foto:'assets/ponentes/Inmaculada.jpg',
    nombre: "Dña. Inmaculada Serrano Sánchez",
    puesto: "Directora General de Aguas de Murcia",
    foto_background:"/assets/ponentes/bg_Inmaculada.jpg",
    dia:'18 de octubre',
    bio:[
      {
        text:'Ingeniera de Caminos, Canales y Puertos por la Universidad de Granada, con más de 20 años de experiencia en la gestión del ciclo integral del agua urbana.',
      },
      {
        text:'Actualmente es Directora General de Aguas de Murcia. Anteriormente, durante ocho años ha sido Directora General, Gerente Territorial y Directora Técnica de Hidrogea.',
      },
      {
        text:'Durante los dieciséis años anteriores que trabajó en Emuasa, ejerció diferentes responsabilidades, siendo nombrada en 2008 Directora de Planificación y Obras, donde, durante 6 años, se hizo cargo de toda la actividad relacionada con el desarrollo y mantenimiento de las infraestructuras e instalaciones hidráulicas del municipio que afectaban al ciclo integral de este recurso.',
      },        
    ],    
  },

  {
    id: 4,
    foto:'assets/ponentes/Teodoro.jpg',
    nombre: "Dr. Teodoro Estrela Monreal",
    puesto: "Director General del Agua, Secretaría de Estado de Medio Ambiente, Ministerio para la Transición Ecológica y el Reto Demográfico",
    foto_background:"/assets/ponentes/bg_Teodoro.jpg",
    dia:'19 de octubre',
    bio:[
      {
        text:'Doctor Ingeniero de Caminos Canales y Puertos e Ingeniero de Caminos Canales y Puertos por la Universitat Politècnica de València. Desde el año 2020 es Director General del Agua, Secretaría de Estado de Medio Ambiente, Ministerio para la Transición Ecológica y el Reto Demográfico por Real Decreto 126/2020, de 21 de enero.',
      },
      {
        text:'Anteriormente ha sido Jefe de la Oficina de Planificación Hidrológica y Director Adjunto de la Confederación Hidrográfica del Júcar, Director General del Agua en funciones del Ministerio de Medio Ambiente y Medio Rural y Medio Marino y Subdirector General de Planificación y Usos Sostenible del Agua de la Dirección General del Agua del Ministerio de Medio Ambiente, así como Coordinador técnico-científico del Área de Hidrología y Director de programas de Hidrología General del Centro de Estudios Hidrográficos del Centro de Estudios y Experimentación de Obras Públicas (CEDEX).',
      },
      {
        text:'Su trabajo en la Administración del Agua lo ha venido compatibilizando en el ámbito académico, desde el año 2005, como Profesor Asociado de la Universitat Politécnica de Valencia, donde ha dirigido 5 tesis doctorales y ha publicado numerosos artículos en revistas científicas.',
      },
      {
        text:'Ha sido también Secretario Técnico Permanente de la Red Mediterránea de Organismos de Cuenca y recientemente Presidente del Comité de Agua del Consejo Mundial de Ingenieros Civiles.'
      }        
    ],    
  },
  {
    id: 5,
    foto:'assets/ponentes/Petra.jpg',
    nombre: "Prof. Dra. Petra Amparo López Jiménez",
    puesto: "Catedrática de Universidad en la Universitat Politècnica de València",
    foto_background:"/assets/ponentes/bg_Petra.jpg",
    dia:'19 de octubre',
    bio:[
      {
        text:'Bachelor en Combined Engineering por la Universidad Coventry Polytechnic, Coventry, Reino Unido, en 1992; Ingeniera Industrial por la Escuela Técnica Superior de Ingenieros Industriales de la Universitat Politècnica de València (UPV), en 1994 y Doctora Ingeniera Industrial por la Universitat Politècnica de València en el programa de doctorado de Ingeniería Hidráulica y Medio Ambiente, en 2001.',
      },
      {
        text:'Catedrática de Universidad en la Universitat Politècnica de València y Directora del Departamento de Ingeniería Hidráulica y Medio Ambiente de dicha universidad. Ha sido Vicepresidenta de la Asociación Internacional de Ingeniería e Investigación Hidroambiental (IAHR).',
      },
      {
        text:'Desde que comenzó su actividad profesional, pertenece al Departamento de Ingeniería Hidráulica y Medio Ambiente de la UPV, en el que ejerce como subdirectora desde 2008. En él siempre ha ejercido labores de investigación relacionadas con la ingeniería hidráulica y la modelación de los procesos de la mecánica de fluidos y la dispersión de contaminantes.',
      },
      {
        text:'Es autora de cien artículos en publicaciones indexadas WOS y de veintiuno en publicaciones no indexadas (revisadas por pares) y de multitud de contribuciones a congresos y capítulos de libro, habiendo dirigido tres tesis doctorales que fueron evaluadas con sobresaliente cum laude.'
      },
      {
        text:'Desde el curso 1996-1997, se encuentra involucrada en la docencia en el ámbito de la ingeniería hidráulica, tanto en programas de grado como de máster. En la actualidad ocupa la categoría de Profesora Titular de Universidad, pero cuenta con la acreditación a Catedrática de Universidad desde 2015.'
      }      
    ],    
  },






  {
    id: 1,
    foto:'assets/ponentes/Valero.jpg',
    nombre: "Dr. Daniel Valero Huerta",
    puesto: "Karlsruhe Institute of Technology (KIT)",
    foto_background:"/assets/ponentes/bg_Valero.jpg",
    dia:'17 de octubre',

    bio:[
      {
        text:'Daniel Valero, es Ingeniero de Caminos, Canales y Puertos (nivel Master Eng., acreditado por la ABET) por la Universitat Politecnica de Valencia (España), de donde también obtuvo un posterior Master en Ingeniería Hidráulica y Medio Ambiente. Obtuvo su doctorado en la Universidad de Lieja (Bélgica) y trabajó en el Laboratorio de Hidráulica de la Universidad de Ciencias Aplicadas de Aquisgrán (Alemania) durante cuatro años.',
      },
      {
        text:'Es investigador senior en el Instituto Tecnológico de Karlsruhe (KIT, Alemania) y profesor en el Instituto de Educación Hídrica de Delft (Países Bajos). Actualmente representa a los jóvenes profesionales europeos de la Asociación Internacional de Ingeniería e Investigación Hidroambiental (IAHR).',
      },
  
      {
        text:'También ha realizado experimentos en otros laboratorios hidráulicos (UPV, The University of Queensland, TU Delft, Utah State University).',
      },
      {
        text:'Su principal experiencia está relacionada con la mecánica de fluidos ambientales de los flujos multifásicos, incluidos los flujos aire-agua, agua-sedimento y, más recientemente, los flujos plástico-agua; y el diseño hidráulico de estructuras de ingeniería.',
      },
      {
        text:'Es autor de multitud de trabajos y artículos de modelización experimental y desarrollo de instrumentación multifásica, así como en la modelización numérica de flujos multifásicos turbulentos.',
      },
      {
        text:'Dentro de sus líneas principales de investigación, actualmente se encuentra el transporte turbulento de plásticos en ríos y estructuras hidráulicas en el contexto de la variabilidad climática.',
      },
     
    ],
  },
    
    
   
    
   
    {
      id: 7,
      foto:'assets/ponentes/Ojeda.jpg',
      nombre: "D. Juan Ojeda Couchoud",
      puesto: "Director del Departamento de Ingeniería del Agua del Grupo TYPSA",
      foto_background:"/assets/ponentes/bg_Ojeda.jpg",
      dia:'19 de octubre',
      bio:[
        {
          text:'Ingeniero de Caminos Canales y Puertos por la Universidad Politécnica de Madrid.'
        },
        {
          text:'Director del Departamento de Ingeniería del Agua del Grupo TYPSA.'
        },
        {
          text:'Vocal colaborador del Comité Español de Grandes Presas (SPANCOLD)dentro del grupo de trabajo de “Sedimentación de Embalses”.'
        },
      ],
    },
    {
      id: 8,
      foto:'assets/ponentes/Francisca.jpg',
      nombre: "Dña. Francisca Baraza Martínez",
      puesto: "Presidenta de la Mancomunidad de los Canales del Taibilla",
      foto_background:"/assets/ponentes/bg_taibilla.jpg",
      dia:'19 de octubre',
      bio:[
        {
          text:'Funcionaria de carrera en la administración Pública desde hace más de 40 años.'
        },
        {
          text:'Licenciada en Biología por la Universidad de Murcia. Master en Educación Ambiental por la UNED.'
        },
        {
          text:'A lo largo de su dilatada historia de servicio público, ha desarrollado tareas en educación y medio ambiente fundamentalmente, tanto en la administración del Estado como en la administración de la Comunidad Autónoma de la Región de Murcia.'
        },
        {
          text:'Ha desarrollado tareas de planificación y gestión de los espacios naturales protegidos, la Red Natura 2000 y otras figuras de protección a nivel internacional en la Comunidad Autónoma de la Región de Murcia, destacando entre otros hitos el desarrollo de la Estrategia Regional para la Conservación y el Uso Sostenible de la Diversidad Biológica” (BORM de 18 de diciembre de 2003) y el “Proyecto Piloto de Gestión Integrada de Zonas Costeras aplicado a la Laguna del Mar Menor y su Área de Influencia” Proyecto C.A.M.P (Coastal Area Management Programme)- proyecto piloto a nivel de la Unión Europea (2001-2003).'
        },
        {
          text:'Profesora-ponente en el Master de Especialista en Espacios Naturales Protegidos, organizado por las Universidades Autónoma y Complutense de Madrid y la Universidad de Alcalá, (ediciones de 2001 a 2007).'
        },
        {
          text:'Ha sido responsable de la Demarcación de Costas del Estado en la Región de Murcia durante siete años (2005-2012).'
        },
        {
          text:'En la actualidad es responsable de la coordinación de las actuaciones que el Ministerio para la Transición Ecológica y el Reto Demográfico está desarrollando para la recuperación del Mar Menor (Marco de Actuaciones Prioritarias para recuperar el Mar Menor) y es Presidenta de la Mancomunidad de los Canales del Taibilla desde 2018.'
        },
        
      ],
    },
    {
      id: 9,
      foto:'assets/ponentes/Cascales.jpg',
      nombre: "D. Juan Cascales Salinas",
      puesto: "Director Adjunto de la Mancomunidad de los Canales del Taibilla",
      foto_background:"/assets/ponentes/bg_taibilla.jpg",
      dia:'18 de octubre',
      bio:[
        {
          text:'Ingeniero de Caminos, Canales y Puertos por la Universidad Politécnica de Valencia. Master Universitario en Dirección y Liderazgo Públicos por la Universidad Internacional Menéndez Pelayo.'
        },
        {
          text:'Funcionario de carrera perteneciente al Cuerpo de Ingenieros de Caminos, Canales y Puertos del Estado.'
        },
        {
          text:'Comienza su actividad profesional en el sector privado como jefe de obra en empresa constructora, desarrollando fundamentalmente obras de tipología hidráulica.'
        },
        {
          text:'En 2010 se incorpora a la Mancomunidad de los Canales del Taibilla (MCT) como responsable de las potabilizadoras del organismo. En 2019 se le asigna la dirección del departamento de producción de la MCT, sumando a la gestión de las potabilizadoras la correspondiente a las desaladoras y al departamento de energía. Desde 2021 ejerce como Director Adjunto de la MCT, teniendo asignada la coordinación de los departamentos técnicos del organismo (producción, explotación y proyectos y obras).'
        },
        
        
      ],
    },
    
    
    
   

    
  
  ];
  export default Data