const comite_permanente_vocales = [
    {
      nombre:'Luis Gerardo Castillo Elsitdié',
      centro:'UPCT'
    }, 
    {
      nombre:'Carlos Conradi Monner ',
      centro:'MCT'
    },
    {
      nombre:'Luis Garrote de Marcos',
      centro:'UPM'
    },
    {
      nombre:'Ramón Gutiérrez Serret',
      centro:'IAHR'
    },
    {
      nombre:'Jerónimo Puertas Agudo',
      centro:'UDC'
    },
    {
      nombre:'José Roldán Cañas',
      centro:'FFIA'
    },

    {
      nombre:'Alejandro Román Arroyo',
      centro:'Iberdrola'
    },

    {
      nombre:'Mario Andrés Urrea Mallebrera',
      centro:'CH Segura'
    },
   

    

  ];
  export default comite_permanente_vocales