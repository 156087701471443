const Data = [
  {
    key: 1,
    titulo: "Carrera investigadora en centro Europa - oportunidades, dificultades y una perspectiva personal en ingeniería del agua",
    autor:'Dr. Daniel Valero Huerta',
    institucion:'Karlsruhe Institute of Technology (KIT)',
    descripcion:'Algun texto interesante',
    foto_autor:'assets/ponentes/Valero.jpg',
    foto_trabajo:'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
    tematica:'A.	Hidrología y Dinámica Fluvial',
    dia:'17/10/2023',
    hora:'19:00-19:30',
    lugar:'E.T.S. de Ingeniería de Caminos, Canales y Puertos y de Ingeniería de Minas',
    sala:'Salón de actos',
    tags: ['tag3'],
  },
  {
    key: 2,
    titulo: "Hydraulic structures",
    autor:'Prof. Dr. Jorge Matos',
    institucion:'Instituto Superior Técnico, Universidade de Lisboa',
    descripcion:'Algun texto interesante',
    foto_autor:'assets/ponentes/Matos.jpg',
    foto_trabajo:'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
    tematica:'A.	Hidrología y Dinámica Fluvial',
    dia:'18/10/2023',
    hora:'09:45-10:15',
    lugar:'Campus Muralla del Mar de la UPCT. Antiguo Hospital Militar de Marina',
    sala:'Paraninfo',
    tags: ['tag5', 'tag6', 'tag7'],
  },
  {
    key: 3,
    titulo: "El binomio agua-energía: el caso de la Mancomunidad de los Canales del Taibilla",
    autor:'D. Juan Cascales Salinas',
    institucion:'Mancomunidad de los Canales del Taibilla',
    descripcion:'Algun texto interesante',
    foto_autor:'assets/ponentes/Cascales.jpg',
    foto_trabajo:'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
    tematica:'B - Usos y gestión del agua',
    dia:'18/10/2023',
    hora:'18:45-19:15',
    lugar:'Campus Muralla del Mar de la UPCT. Antiguo Hospital Militar de Marina',
    sala:'Paraninfo',
    tags: ['tag5', 'tag6', 'tag7'],
  },

  {
    key: 4,
    titulo: "La gestión del agua urbana como paradigma de la circularidad en la Región de Murcia",
    autor:'Dña. Inmaculada Serrano Sánchez',
    institucion:'Directora General de Aguas de Murcia',
    descripcion:'Algun texto interesante',
    foto_autor:'assets/ponentes/Inmaculada.jpg',
    foto_trabajo:'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
    tematica:'B - Usos y gestión del agua',
    dia:'19/10/2023',
    hora:'9:15-9:45',
    lugar:'Campus Muralla del Mar de la UPCT. Antiguo Hospital Militar de Marina',
    sala:'Paraninfo',
    tags: ['tag5', 'tag6', 'tag7'],
  },

  {
    key: 5,
    titulo: "Las soluciones basadas en la naturaleza en el Marco de Actuaciones Prioritarias para la recuperación del Mar Menor",
    autor:'Dña Francisca Baraza Martínez',
    institucion:'Mancomunidad de los Canales del Taibilla',
    descripcion:'Algun texto interesante',
    foto_autor:'assets/ponentes/Francisca.jpg',
    foto_trabajo:'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
    tematica:'B - Usos y gestión del agua',
    dia:'19/10/2023',
    hora:'10:45-11:15',
    lugar:'Campus Muralla del Mar de la UPCT. Antiguo Hospital Militar de Marina',
    sala:'Paraninfo',
    tags: ['tag5', 'tag6', 'tag7'],
  },

  {
    key: 6,
    titulo: "Evaluando la Sostenibilidad en la Ingeniería del Agua",
    autor:'Prof. Dra. Petra Amparo López Giménez',
    institucion:'Catedrática de Universidad en la Universitat Politècnica de València',
    descripcion:'Algun texto interesante',
    foto_autor:'assets/ponentes/Petra.jpg',
    foto_trabajo:'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
    tematica:'B - Usos y gestión del agua',
    dia:'19/10/2023',
    hora:'12:30-13:00',
    lugar:'Campus Muralla del Mar de la UPCT. Antiguo Hospital Militar de Marina',
    sala:'Paraninfo',
    tags: ['tag5', 'tag6', 'tag7'],
  },
  {
    key: 7,
    titulo: "Adaptación al Cambio Climático en la gestión del agua en España",
    autor:'Dr. Teodoro Estrela Monreal',
    institucion:'Secretaría de Estado de Medio Ambiente, Ministerio para la Transición Ecológica y el Reto Demográfico',
    descripcion:'Algun texto interesante',
    foto_autor:'assets/ponentes/Teodoro.jpg',
    foto_trabajo:'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
    tematica:'B - Usos y gestión del agua',
    dia:'19/10/2023',
    hora:'15:00-15:30',
    lugar:'Campus Muralla del Mar de la UPCT. Antiguo Hospital Militar de Marina',
    sala:'Paraninfo',
    tags: ['tag5', 'tag6', 'tag7'],
  },  
  {
    key: 8,
    titulo: "Estado sedimentológico de embalses en España. Futuro y Estrategias de Operación",
    autor:'D. Juan Ojeda Couchoud',
    institucion:'Director del Departamento de Ingeniería del Agua del Grupo TYPSA',
    descripcion:'Algun texto interesante',
    foto_autor:'assets/ponentes/Ojeda.jpg',
    foto_trabajo:'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
    tematica:'A.	Hidrología y Dinámica Fluvial',
    dia:'19/10/2023',
    hora:'18:15-18:45',
    lugar:'Campus Muralla del Mar de la UPCT. Antiguo Hospital Militar de Marina',
    sala:'Paraninfo',
    tags: ['tag3', 'tag4'],
  },
 
  
  
  
 
];
export default Data