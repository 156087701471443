import React from "react"
import { useEffect } from "react";
import { Link } from "react-router-dom"
import styles from "./eventos.module.css";
import './salas.css'


const Salas = () => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <section className='Seccion_white'>
      <div className='container'>
          <div className='heading'>
            <h3>Salas</h3>
            <h1>Organización de las ponencias</h1>
          </div>
          <div style={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         marginTop:'20px',
                         marginBottom:'50px'
                         }}>
                              <button className={styles.primary_btn}>
                                  <Link to="/assets/docs/Salas congreso VII JIA  y ubicación días 18 y 19 .pdf" target="_blank" style={{color:'white'}} >DESCARGAR SALAS Y UBICACIONES DIAS 18 y 19 </Link>


                              </button>
                          
            </div>
          <h1>Campus Muralla del Mar. Antiguo Hospital de Marina</h1>

                    <div className="grid_ods_salas">
                         <div>
                          <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                              Planta Sótano
                            </p>
                            <img src='/assets/salas/Sotano.png' alt=''  width='90%' />
                         </div>

                         <div>
                            <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                                Planta Baja
                            </p>
                            <img src='/assets/salas/Planta1A.png' alt=''  width='90%' />
                         </div>

                         <div>
                            <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                                Planta Primera
                            </p>
                            <img src='/assets/salas/Planta1B.png' alt='' width='90%' />
                         </div>

                         <div>
                          <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                              Planta Segunda
                          </p>
                            <img src='/assets/salas/Planta2.png' alt=''  width='90%' />
                         </div>
                    </div>          
              <img style={{borderRadius:'10px',marginTop:'50px'}}src='/assets/salas/Patio.jpg' alt='' width='90%'  />              
          </div>      
      </section>
    </>
  )
}

export default Salas
