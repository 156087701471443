import React from "react"
import { useEffect } from "react";
import './estilos.css'



const Curso_IBER_Basico = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    <>
      <section className='Seccion_white_contenedor'>
            <div className='heading'>
              <h3>Curso de IBER Básico</h3>
              <h1>Curso de Iniciación a la Modelización Bidimensional de Inundaciones con Iber</h1>
              <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>17 de octubre 2023</h1>

            </div>
      </section>
      <section >

          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Horario del curso:</h1>

          <div className='container'>        
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Duración curso de iniciación: <b>3.5 horas</b>
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Hora de inicio: <b>9:00</b>
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Hora de fin: <b>12:30</b>
                  </p>
          </div> 

          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Aula del curso:</h1>

          <div className='container'>        
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    <b>Aula N1.2</b> situada en la 1ª planta del Aulario B parte trasera de Edificio ETSI de Caminos, Canales y Puertos y de Ingeniería de Minas situado en Paseo Alfonso XIII 52, 30203 Cartagena, Murcia (<b><a href="/assets/docs/Ubicacion Aulas Cursos Precongresos.pdf" target="_blank" rel="noreferrer">Pinchar para ver ubicación</a></b>)
                  </p>
                  
          </div> 

          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Profesores responsables del curso:</h1>

          <div className='container'>        
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                          Luis Cea Gómez (UDC) <a style={{textDecoration:'underline'}} href='mailto:luis.cea@udc.es'>luis.cea@udc.es</a>
                      </li>
                      
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Ernest Bladé Castellet (UPC) <a style={{textDecoration:'underline'}} href='mailto:ernest.blade@upc.edu'>ernest.blade@upc.edu</a>
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Marcos Sanz Ramos (UPC) <a style={{textDecoration:'underline'}} href='mailto:marcos.sanz-ramos@upc.edu'>marcos.sanz-ramos@upc.edu</a> 
                      </li>
              </ul>  
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Esteban Sañudo Costoya (UDC) <a style={{textDecoration:'underline'}} href='mailto:e.sanudo@udc.es'>e.sanudo@udc.es</a> 
                      </li>
              </ul>  
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Gonzalo García Alén (UDC) <a style={{textDecoration:'underline'}} href='mailto:g.glores@udc.es'>g.glores@udc.es</a> 
                      </li>
              </ul>  
          </div> 
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Contenido del curso:</h1> 
          <div className='container'> 
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Unidad 1. Qué es y para qué sirve (30’)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Presentación de la herramienta numérica, marco de desarrollo, conceptos teóricos y aplicaciones.                
                  </p>
              </div>
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Unidad 2. Introducción a Iber (60’)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Caso de estudio para familiarizarse con la interfaz gráfica y flujo de trabajo de Iber. Se mostrarán procesos para la generación de modelos numérico en Iber, qué es la geometría y la malla del modelo, opciones de cálculo y primeros análisis de resultados.
                  </p>
              </div>
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Unidad 3. Iber en 2 pasos (60’)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Caso de estudio para afianzar los conocimientos. Trabajo con geometrías complejas, criterios de mallado, modelos digitales del terreno y profundización en el análisis de resultados. También se muestra el carácter bidimensional de las inundaciones, así como la implementación de puentes.                  </p>
              </div>
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Unidad 4. Modelización de inundaciones por rotura de balsas (60’)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Caso de estudio para mostrar el proceso de formación de brechas con Iber y la subsiguiente propagación de la onda de avenida. Introducción al método completo para la realización de estudios de clasificación de balsas y presas.
                  </p>
              </div>
              
          </div> 

          <div className='container' style={{marginBottom:'20px'}}> 
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    El curso de iniciación dará paso al curso avanzado. Ambos cursos se realizan uno a continuación del otro en el mismo aula. 
                </p> 
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Cada persona matriculada deberá traer su propio portátil de trabajo con SO Windows.
                </p>  
              
                            
          </div> 
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Organización:</h1> 
          <div className='container' style={{marginBottom:'20px'}}> 
        
            <div className="grid_img_cursos">
              <img src='/assets/footer/colaboradores/RLHE.jpg' alt='' width='200px'/>                         
          </div>

          
            <p style={{textAlign:'justify', fontSize:'1rem', marginBottom:'20px'}}>
            Curso organizado por la Red de Laboratorios de Hidráulica de España (RLHE)
                
            </p>
          </div>

          
              
        
         
          
      </section>
      

    </>
  )
}

export default Curso_IBER_Basico
