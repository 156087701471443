import React from "react"
import VideoHeader from "./Elementos/VideoHeader/VideoHeader"
import Lineas_Tematicas from "./Elementos/Lineas_Tematicas/Lineas_Tematicas"



import Countdown from "./Elementos/countdown/Countdown"
import Eventos from "./Elementos/Eventos/Eventospage"
import Presentacion from "./Elementos/Presentacion/Presentacionpage"
import OrganizadorPromotor from "./Elementos/Organizador_Promotor/OrganizadorPromotor"




const HomePages = () => {
  return (
    <>
      <VideoHeader /> 
      <Presentacion /> 
      <OrganizadorPromotor/>
      <Lineas_Tematicas />
      <Eventos />
      

    </>
  )
}

export default HomePages
