import React from "react"
import "./App.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Footer from "./components/footer/Footer"
import Header from "./components/header/Header"
import HomePages from "./components/Secciones/Home/HomePages"
import Pages from "./components/Secciones/Generica/GenericaPage"

//Organizacion
import Organizacion from "./components/Secciones//Organizacion/organizacionpage"



//Instrucciones
import Instrucciones_Inscripcion from "./components/Secciones/Instrucciones/Inscripcion/InscripcionPage"
import Instrucciones_Autores from "./components/Secciones/Instrucciones/Autores/AutoresPage"
import Instrucciones_Premios from "./components/Secciones/Instrucciones/Premios/PremiosPage"

//Logisticas
import Logisticas_Alojamiento from "./components/Secciones/Logistica/Alojamiento/AlojamientoPage"
import Logistica_Sede from "./components/Secciones/Logistica/Sede/Sedepage"
import Logisticas_Desplazamiento from "./components/Secciones/Logistica/Desplazamiento/DesplazamientoPage"

//Web Externas integradas
import InscripcionSymposiumPage from "./components/Secciones/Inscripcion_Symposium/InscripcionSymposiumPage"

import Eventos from "./components/Secciones/Home/Elementos/Eventos/Eventospage"

//Programacion
import Programacion_Jornada from "./components/Secciones/Programacion/programacion_jornada/programacion_jornadapage"
import Monografico from "./components/Secciones/Programacion/monografico/monograficopage"
import Salas from "./components/Secciones/Programacion/Salas/Salaspage"
import Posters from "./components/Secciones/Programacion/posters/posterspage"
import Ponencias_invitadas from "./components/Secciones/Programacion/Ponentes/PonentesPage"

import comunicacionesOrales from "./components/Secciones/Programacion/comunicacionesOrales/comunicacionesOralespage"

import Curso_IBER_Basico from "./components/Secciones/Programacion/Cursos/Curso_IBER_Basico"
import Curso_IBER_Avanzado from "./components/Secciones/Programacion/Cursos/Curso_IBER_Avanzado"
import Curso_SUDS from "./components/Secciones/Programacion/Cursos/Curso_SUDS"
import Curso_TETIS from "./components/Secciones/Programacion/Cursos/Curso_TETIS"
import Curso_WorkshopUDMT from "./components/Secciones/Programacion/Cursos/Curso_WorkshopUDMT"







const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path='/' exact component={HomePages} />
          <Route path='/pages' exact component={Pages} />

          <Route path='/fechas_clave' exact component={Eventos} />

          <Route path='/organizacion' exact component={Organizacion} />

          


          <Route path='/inscripcion' exact component={Instrucciones_Inscripcion} />
          <Route path='/autores' exact component={Instrucciones_Autores} />
          <Route path='/premios' exact component={Instrucciones_Premios} />

          <Route path='/participa' exact component={InscripcionSymposiumPage} />

          <Route path='/alojamiento' exact component={Logisticas_Alojamiento} />
          <Route path='/sede' exact component={Logistica_Sede} />
          <Route path='/desplazamiento' exact component={Logisticas_Desplazamiento} />

          <Route path='/desplazamiento' exact component={Logisticas_Desplazamiento} />



          <Route path='/programacion_jornada' exact component={Programacion_Jornada} />
          <Route path='/monografico' exact component={Monografico} />
          <Route path='/salas' exact component={Salas} />
          <Route path='/posters' exact component={Posters} />
          <Route path='/ponencias_invitadas' exact component={Ponencias_invitadas} />
          <Route path='/comunicaciones_orales' exact component={comunicacionesOrales} />
          <Route path='/curso_iber_basico' exact component={Curso_IBER_Basico} />
          <Route path='/curso_iber_avanzado' exact component={Curso_IBER_Avanzado} />
          <Route path='/curso_suds' exact component={Curso_SUDS} />
          <Route path='/curso_tetis' exact component={Curso_TETIS} />
          <Route path='/curso_workshop_udmt' exact component={Curso_WorkshopUDMT} />

          
          
          


        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default App
