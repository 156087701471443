import React from "react"
import { useEffect } from "react";
import Avion from "./Secciones/Avion"
import Tren from "./Secciones/Tren"
import Autobus_Coche from "./Secciones/Autobus_Coche"
import Dentro_Ciudad from "./Secciones/Dentro_Ciudad"





const Autores = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    <>
      <section className='Seccion_white_contenedor'>
            <div className='heading'>
              <h3>Desplazamiento</h3>
              <h1>¿Cómo llegar a las Jonadas de Ingeniería del Agua 2023?</h1>
            </div>
      </section>
      
      <Avion/>
      <Tren/>
      <Autobus_Coche />
      <Dentro_Ciudad />
    </>
  )
}

export default Autores
