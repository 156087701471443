import React from "react"
import { useEffect } from "react";
import { Link } from "react-router-dom"
import styles from "./videoheader.module.css";
const VideoHeader = () => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
        <div class={styles.container2}>
              <video id="video" autoPlay loop muted>
                  <source  src='./assets/home/cabecera_web_congreso.mp4' type="video/mp4" />
              </video>
              <div class={styles.overlay2} style={{ marginTop:'5%', marginRight:'0px', padding:'10px 0px 10px 0px'}}>
                  <div style={{ justifyContent: 'flex', width:'800px'  }}>
                  <div style={{ margin:'0px 0px 0px -20px',width:'100%', display:'flex', flexDirection:'row',  justifyContent: 'center', alignContent:'center'}}>
                    <div style={{width:'30%', marginRight:'40px'}}>
                    <img     style={{height: 200}}  src='assets/logojia2023.png' alt='' />
                    </div>
                    <div style={{width:'70%', margin:'auto'}}>
                      <div style={{width:'90%'}}>
                      <h3 style={{fontSize:'37px', textAlign:'left', fontWeight:'500', textTransform:'uppercase', color:'#000', alignContent:'center', alignItems:'center'}}>VII JORNADAS DE INGENIERÍA DEL AGUA</h3>

                      </div>
                        <h2 style={{lineHeight: '0px',fontSize:'20px', fontWeight:'500', textTransform:'uppercase', color:'#3896d1', alignContent:'center', alignItems:'center'}}>Universidad Politécnica de Cartagena</h2>
                        
                    </div>
                  </div>     
                 

             
                    
                     
                    </div>
              </div>
        </div>
    </>
  )
}

export default VideoHeader
