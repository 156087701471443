const Data = [
  {
    id: 0,
    hotel: "HOSTEL LOOP INN CARTAGENA",
    estrellas:0,
    descripcion:'Este albergue, con vistas a la montaña, se encuentra a 3 minutos a pie del Museo Regional de Arte Moderno y a 7 de la estación de tren de Cartagena. Todos aquellos asistentes al Congreso que se alojen en nuestro establecimiento se beneficiarán de un 12% de descuento sobre la tarifa en vigor, contratando en la web e identificarse como asistentes al Congreso para beneficiarse del descuento.',
    precio_habitacion_individual:'39€',
    text_habitacion_individual:'Habitación individual',
    text_habitacion_doble:'Habitación doble superior',
    mostrar_datos_habitacion:true,

    info_extra_habitacion_individual:'(IVA incluido)',
    precio_habitacion_doble:'52€',
    info_extra_habitacion_doble:'(IVA incluido)',

    info_reserva:'Realizar la reserva con el código JIA2023',
    
    direccion:'C. San Crispín, 34, 30202 Cartagena, Murcia',
    mapa:'https://www.google.com/maps/place/LoopInn+Hostels/@37.6028919,-0.9791495,15z/data=!4m2!3m1!1s0x0:0xbb55509d29d25952?sa=X&ved=2ahUKEwjM37T-n_D8AhXMyaQKHXK-DdMQ_BJ6BQiAARAH',
    telefono:'+34 868 45 17 60',
    web:'https://loopinnhostels.com/',
    derechos_autor:'HOSTEL LOOP INN CARTAGENA',
    email:'reservas@loopinnhostels.com',
    images:[
      {
        id:1,
        img:'/assets/logistica/alojamiento/Hotel_Loop/1.jpg'
      },
      {
        id:2,
        img:'/assets/logistica/alojamiento/Hotel_Loop/2.jpg'
      },
      {
        id:3,
        img:'/assets/logistica/alojamiento/Hotel_Loop/3.jpg'
      }
    ]
  },

  {
    id: 1,
    hotel: "Hotel NH Cartagena",
    estrellas:4,
    descripcion:'Ubicado en la plaza del ayuntamiento y junto al edificio de la autoridad portuaria se encuentra cerca del casco antiguo de la ciudad y a escasos 5 minutos andando de la sede de las jornadas',
    precio_habitacion_individual:'115€',
    info_extra_habitacion_individual:'(IVA y desayuno incluido)',
    precio_habitacion_doble:'125',
    info_extra_habitacion_doble:'(IVA y desayuno incluido)',
    text_habitacion_individual:'Habitación individual',
    text_habitacion_doble:'Habitación uso doble',
    mostrar_datos_habitacion:true,

    info_reserva:'Realizar la reserva (hasta 25 de Septiembre) con el código JIA2023',
    
    direccion:'Plza. Héroes de Cavite, 30201 Cartagena, Murcia',
    mapa:'https://www.google.es/maps/place/Hotel+NH+Cartagena/@37.5988637,-0.9883544,17z/data=!3m1!4b1!4m8!3m7!1s0xd63422939bee1c7:0x9a40bba1f6867395!5m2!4m1!1i2!8m2!3d37.5988595!4d-0.9861657?hl=es',
    telefono:'+34 968 12 09 08',
    web:'https://www.nh-hoteles.es/hotel/nh-cartagena',
    derechos_autor:'Hotel NH Cartagena',
    email:'????',
    images:[
      {
        id:1,
        img:'/assets/logistica/alojamiento/Hotel_Nh_Cartagena/1.jpg'
      },
      {
        id:2,
        img:'/assets/logistica/alojamiento/Hotel_Nh_Cartagena/2.jpg'
      },
      {
        id:3,
        img:'/assets/logistica/alojamiento/Hotel_Nh_Cartagena/3.jpg'
      }
    ]
  },


  {
    id: 1,
    hotel: "Hotel NH Campo Cartagena",
    estrellas:3,
    descripcion:'Se encuentra a 2 km del Teatro Romano restaurado y del Castillo de la Concepción, ubicado en lo alto de una colina, y a 3 del Museo Naval de Cartagena.',
    precio_habitacion_individual:'71€',
    info_extra_habitacion_individual:'(IVA y desayuno incluido)',
    precio_habitacion_doble:'78',
    info_extra_habitacion_doble:'(IVA y desayuno incluido)',
    text_habitacion_individual:'Habitación individual',
    text_habitacion_doble:'Habitación uso doble',
    mostrar_datos_habitacion:true,

    info_reserva:'Realizar la reserva (hasta 25 de Septiembre) con el código JIA2023',
    
    direccion:'C. Ciudadela, 24, 30203 Cartagena, Murcia',
    mapa:'https://www.google.com/maps/place/Hotel+NH+Campo+Cartagena/@37.612693,-0.976506,15z/data=!4m2!3m1!1s0x0:0xf0d0f8cf74dd4c71?sa=X&ved=2ahUKEwiwlfaTovD8AhUSRuUKHfkLCqUQ_BJ6BQiEARAH',
    telefono:'+34 968 50 44 31',
    web:'https://www.nh-hoteles.es/hotel/nh-campo-cartagena',
    derechos_autor:'Hotel NH Campo Cartagena',
    email:'????',
    images:[
      {
        id:1,
        img:'/assets/logistica/alojamiento/Hotel_Campo_Cartagena/1.jpg'
      },
      {
        id:2,
        img:'/assets/logistica/alojamiento/Hotel_Campo_Cartagena/2.jpg'
      },
      {
        id:3,
        img:'/assets/logistica/alojamiento/Hotel_Campo_Cartagena/3.jpg'
      }
    ]
  },

  {
    id: 2,
    hotel: "Hotel Sercotel Alfonso XIII",
    estrellas:4,
    descripcion:'Ubicado en el Paseo Alfonso XIII junto al ensanche de la ciudad a 10 minutos andando de la sede de las jornadas',
    precio_habitacion_individual:'94€',
    info_extra_habitacion_individual:'(IVA y desayuno incluido)',
    precio_habitacion_doble:'104',
    info_extra_habitacion_doble:'(IVA y desayuno incluido)',
    text_habitacion_individual:'Habitación individual',
    text_habitacion_doble:'Habitación uso doble',
    mostrar_datos_habitacion:true,

    info_reserva:'Realizar la reserva (hasta 25 de Septiembre) con el código JIA2023',
    
    mapa:'https://www.google.es/maps/place/P.%C2%BA+Alfonso+XIII,+40,+30203+Cartagena,+Murcia/@37.6066926,-0.9847612,17z/data=!3m1!4b1!4m5!3m4!1s0xd634189e0bec99f:0xbf2f2bcaae06adb!8m2!3d37.6066884!4d-0.9825725?hl=es',
    direccion:'P.º Alfonso XIII, 40, 30203 Cartagena, Murcia',
    telefono:'+34 968 52 00 00',
    web:'https://www.hotelalfonsoxiii.com/',
    derechos_autor:'Hotel Sercotel Alfonso XIII',
    email:'????',
    images:[
      {
        id:1,
        img:'/assets/logistica/alojamiento/Hotel_Sercotel_AlfonsoXIII/1.jpg'
      },
      {
        id:2,
        img:'/assets/logistica/alojamiento/Hotel_Sercotel_AlfonsoXIII/2.jpg'
      },
      {
        id:3,
        img:'/assets/logistica/alojamiento/Hotel_Sercotel_AlfonsoXIII/3.jpg'
      }
    ]
  },
  {
    id: 3,
    hotel: "B&B Hotel Cartagena Cartagonova",
    estrellas:4,
    descripcion:'Ubicado en el casco antiguo de la ciudad y a escasos 10 minutos andando de la sede de las jornadas',
    precio_habitacion_individual:'59€',
    info_extra_habitacion_individual:'(IVA y desayuno incluido)',
    precio_habitacion_doble:'69€',
    info_extra_habitacion_doble:'(IVA y desayuno incluido)',
    text_habitacion_individual:'Habitación individual',
    text_habitacion_doble:'Habitación uso doble',
    mostrar_datos_habitacion:true,

    info_reserva:'',
    mapa:'https://www.google.es/maps/place/C.+Marcos+Redondo,+3,+30201+Cartagena,+Murcia/@37.6042222,-0.9929035,17z/data=!3m1!4b1!4m5!3m4!1s0xd6342253935eaa1:0x11d538a60ebd715a!8m2!3d37.604218!4d-0.9907148?hl=es',
    direccion:'Calle Marcos Redondo, 3 , 30201 Cartagena, España',
    telefono:'+34 968 504 200',
    web:'https://www.hotel-bb.com/es',
    derechos_autor:'B&B Hotel Cartagena Cartagonova',
    email:'hotel.cartagenacartagonova@hotelbb.com',
    images:[
      {
        id:1,
        img:'/assets/logistica/alojamiento/Hotel_BBCartagonova/1.jpg'
      },
      {
        id:2,
        img:'/assets/logistica/alojamiento/Hotel_BBCartagonova/2.jpg'
      },
      {
        id:3,
        img:'/assets/logistica/alojamiento/Hotel_BBCartagonova/3.jpg'
      }
    ]
  },
  {
    id: 4,
    hotel: "Hotel Los Habaneros",
    estrellas:3,
    descripcion:'Ubicado cerca de la zona portuaria del casco antiguo de la ciudad y de las sede de las jornadas, a escasos 7 minutos andando de la sede de las jornadas',
    precio_habitacion_individual:'65€',
    info_extra_habitacion_individual:'(IVA y desayuno incluido)',
    precio_habitacion_doble:'75€',
    info_extra_habitacion_doble:'(IVA y desayuno incluido)',
    text_habitacion_individual:'Habitación individual',
    text_habitacion_doble:'Habitación uso doble',
    mostrar_datos_habitacion:true,

    info_reserva:'Realizar la reserva (hasta 25 de Septiembre) con el código JIA2023',
    mapa:'https://www.google.es/maps/place/C.+San+Diego,+60,+30202+Cartagena,+Murcia/@37.6035006,-0.9809976,17z/data=!3m1!4b1!4m5!3m4!1s0xd63418890d7baa3:0xf60f43d412eba20!8m2!3d37.6034964!4d-0.9788089?hl=es',
    direccion:'Calle de San Diego 60, 30202 Cartagena, Murcia',
    telefono:'+34 968 50 52 50',
    web:'https://www.hotelhabaneroscartagena.com/',
    derechos_autor:'Hotel Los Habaneros',
    email:'info@hotelhabaneros.com',
    images:[
      {
        id:1,
        img:'/assets/logistica/alojamiento/Hotel_Los_Habaneros/1.jpg'
      },
      {
        id:2,
        img:'/assets/logistica/alojamiento/Hotel_Los_Habaneros/2.jpg'
      },
      {
        id:3,
        img:'/assets/logistica/alojamiento/Hotel_Los_Habaneros/3.jpg'
      }
    ]
  },
  {
    id: 5,
    hotel: "Hotel Sercotel Carlos III",
    estrellas:3,
    descripcion:'Ubicado en la Calle Carlos III, situada detrás del Paseo Alfonso XIII junto al ensanche de la ciudad a 10 minutos andando de la sede de las jornadas',
    precio_habitacion_individual:'84€',
    info_extra_habitacion_individual:'(IVA y desayuno incluido)',
    precio_habitacion_doble:'94€',
    info_extra_habitacion_doble:'(IVA y desayuno incluido)',
    text_habitacion_individual:'Habitación individual',
    text_habitacion_doble:'Habitación uso doble',
    mostrar_datos_habitacion:true,

    info_reserva:'Realizar la reserva (hasta 25 de Septiembre) con el código JIA2023',
    mapa:'https://www.google.es/maps/place/C.+Carlos+III,+49,+30203+Cartagena,+Murcia/@37.6060545,-0.9845438,17z/data=!3m1!4b1!4m5!3m4!1s0xd63422763f0e529:0x774e51d2245baf6!8m2!3d37.6060503!4d-0.9823551?hl=es',
    direccion:'C. Carlos III, 49, 30203 Cartagena, Murcia',
    telefono:'+34 968 52 00 32',
    web:'https://www.carlosiiihotel.com/',
    derechos_autor:'Hotel Sercotel Carlos III',
    email:'reservas@carlosiiihotel.com',
    images:[
      {
        id:1,
        img:'/assets/logistica/alojamiento/Hotel_Sercotel_CarlosIII/1.jpg'
      },
      {
        id:2,
        img:'/assets/logistica/alojamiento/Hotel_Sercotel_CarlosIII/2.jpg'
      },
      {
        id:3,
        img:'/assets/logistica/alojamiento/Hotel_Sercotel_CarlosIII/3.jpg'
      }
    ]
  },
  

];
export default Data