import React from "react"
import { Link } from "react-router-dom"


const Dentro_Ciudad = () => {
  
  return (
    <>
      <section style={{backgroundColor:'#f8f9fe', padding:'20px'}}>
          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left'}}>Dentro de la ciudad</h3>
          <div className='container'>
            <div style={{margin:'0px 0px 0px 0px'}}>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                El servicio de taxis se puede solicitar a <b>Teletaxi (988 531 313)</b> o a <b>RadioTaxi (968 520 404)</b>.  
                </p>
               
           
                
            </div>          
          </div>  
      </section>
    </>
  )
}

export default Dentro_Ciudad
