import React from "react"
import { useEffect } from "react";
import Envio_Resumen_ComunicacionPage from "./Secciones/Envio_Resumen_ComunicacionPage"
import DescuentoPage from "./Secciones/DescuentoPage"
import PresentacionesPage from "./Secciones/PresentacionesPage"



const Autores = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    <>
      <section className='Seccion_white_contenedor'>
            <div className='heading'>
              <h3>Autores</h3>
              <h1>Información para la presentación de trabajos y comunicación oral</h1>
            </div>
      </section>
      
      <Envio_Resumen_ComunicacionPage/>
      <DescuentoPage/>
      <PresentacionesPage/>

    </>
  )
}

export default Autores
