const Data = [
    {
      id: 1,
      letra:'A',
      linea: "Hidrología y Dinámica Fluvial",
      linea2: '',
      foto_background:"/assets/ods/bgcabecera/bf_dinamica_fluvial.jpg",
      puntos:[
        {
          id:1,
          texto:'Caracterización de régimen regular y extremos',
        },
        {
          id:2,
          texto:'Fenómenos de crecidas',
        },
        {
          id:3,
          texto:'Cuencas y masas de agua continentales y de transición',
        },
        {
          id:4,
          texto:'Dinámica de sedimentos en cursos fluviales y costas',
        },
        {
          id:5,
          texto:'Estados ecológicos',
        },
        {
          id:6,
          texto:'Limnología',
        },
        {
          id:7,
          texto:'Estudios de inudabilidad',
        }
      ],
      ods:[
        {
          id:1,
          img:'/assets/ods/odslogos/ODS6.jpg'
        },
        {
          id:2,
          img:'/assets/ods/odslogos/ODS14.jpg'
        },
        {
          id:3,
          img:'/assets/ods/odslogos/ODS15.jpg'
        }
      ]
    },
    {
      id: 2,
      letra:'B',
      linea: "Usos y gestión del agua",
      linea2: '',
      foto_background:"/assets/ods/bgcabecera/bg_uso_y_gestionde_las_aguas.jpg",
      puntos:[
        {
          id:1,
          texto:'Sistemas de recursos hídricos',
        },
        {
          id:2,
          texto:'Ordenación y caracterización de usos del agua',
        },
        {
          id:3,
          texto:'Planificación hidrológica',
        },
        {
          id:4,
          texto:'Planes de sequías e inundaciones',
        },
        {
          id:5,
          texto:'Sistemas de regadíos',
        },
        {
          id:6,
          texto:'Reutilización de aguas',
        },
        {
          id:7,
          texto:'Nexo agua-energía',
        },
        {
          id:8,
          texto:'Participación pública y ciudadana',
        }
      ],
      ods:[
        {
          id:1,
          img:'/assets/ods/odslogos/ODS1.jpg'
        },
        {
          id:2,
          img:'/assets/ods/odslogos/ODS2.jpg'
        },
        {
          id:3,
          img:'/assets/ods/odslogos/ODS6.jpg'
        },
        {
          id:4,
          img:'/assets/ods/odslogos/ODS7.jpg'
        },
        {
          id:5,
          img:'/assets/ods/odslogos/ODS12.jpg'
        },
        {
          id:6,
          img:'/assets/ods/odslogos/ODS13.jpg'
        },
        {
          id:7,
          img:'/assets/ods/odslogos/ODS16.jpg'
        }
      ]
    },
    {
      id: 3,
      letra:'C',
      linea: "Agua y ciudad",
      linea2: '',
      foto_background:"/assets/ods/bgcabecera/bg_agua_y_ciudad.jpg",
      puntos:[
        {
          id:1,
          texto:'Abastecimientos urbanos',
        },
        {
          id:2,
          texto:'Gestión de redes de agua potable',
        },
        {
          id:3,
          texto:'Gestión de redes de saneaminto y sistemas de drenaje',
        },
        {
          id:4,
          texto:'Sistemas Urbanos de Drenaje Sostenible (SUDS)',
        },
        {
          id:5,
          texto:'Inundaciones urbanas',
        },
        {
          id:6,
          texto:'Soluciones verdes',
        },
        {
          id:7,
          texto:'Gemelo digital',
        },
        {
          id:8,
          texto:'Sensorización, Soporte en tiempo real, Inteligencia Artificial, Big data, IoT'
        }
      ],
      ods:[
        {
          id:1,
          img:'/assets/ods/odslogos/ODS3.jpg'
        },
        {
          id:2,
          img:'/assets/ods/odslogos/ODS6.jpg'
        },
        {
          id:3,
          img:'/assets/ods/odslogos/ODS11.jpg'
        },
        {
          id:4,
          img:'/assets/ods/odslogos/ODS14.jpg'
        }
      ]
    },
    {
      id: 4,
      letra:'D',
      linea: "Estructuras Hidráulicas",
      linea2: '',
      foto_background:"/assets/ods/bgcabecera/bg_estructuras_hidraulicas.jpg",
      puntos:[
        {
          id:1,
          texto:'Infraestructuras de almacenamiento y control (presas, balsas, depósitos, etc.)',
        },
        {
          id:2,
          texto:'Soluciones basadas en la naturaleza',
        },
        {
          id:3,
          texto:'Infraestructuras de transporte (canales, conducciones forzadas, etc.)',
        },
        {
          id:4,
          texto:'Puertos',
        },
        {
          id:5,
          texto:'Bombeos',
        },
        {
          id:6,
          texto:'Turbinas',
        },
        {
          id:7,
          texto:'Sistemas reversibles',
        },
        {
          id:8,
          texto:'Pasos de fauna',
        }
      ],
      ods:[
        {
          id:1,
          img:'/assets/ods/odslogos/ODS6.jpg'
        },
        {
          id:2,
          img:'/assets/ods/odslogos/ODS7.jpg'
        },
        {
          id:3,
          img:'/assets/ods/odslogos/ODS13.jpg'
        },
        {
          id:4,
          img:'/assets/ods/odslogos/ODS14.jpg'
        },
        {
          id:5,
          img:'/assets/ods/odslogos/ODS15.jpg'
        }
      ]
    },
    {
      id: 5,
      letra:'M',
      linea: 'Monográfico',
      linea2: 'La Resiliencia de las Infraestructuras Hidráulicas ante el Cambio Climático',

      foto_background:"/assets/ods/bgcabecera/bg_monografico.jpg",
      puntos:[
        {
          id:1,
          texto:'Evaluación de la recuperación de la operatividad de infraestructuras hidráulicas frente a escenarios extremos',
        },
        {
          id:2,
          texto:'Planes de gestión de infraestructuras hidráulicas bajo la perspectiva de resiliencia',
        },
        {
          id:3,
          texto:'Actuaciones derivadas del aumento de la resiliencia',
        },
        {
          id:4,
          texto:'Medidas de adaptación al cambio climático',
        },
        {
          id:5,
          texto:'Cálculo del riesgo asociado a escenarios extremos',
        }
      ],
      ods:[
        {
          id:1,
          img:'/assets/ods/odslogos/ODS1.jpg'
        },
        {
          id:2,
          img:'/assets/ods/odslogos/ODS6.jpg'
        },
        {
          id:3,
          img:'/assets/ods/odslogos/ODS13.jpg'
        }
      ]
    },
  
  ];
  export default Data