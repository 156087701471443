import React from "react"
import { useEffect } from "react";


const Pages = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    
    <>
      <section className='pagesContent'>
        <h1>Esto es una pagina cualquiera</h1>
      </section>
    </>
  )
}

export default Pages
