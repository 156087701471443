import React from "react"
import { useEffect } from "react";
import './estilos.css'



const Curso_IBER_Avanzado = () => {
  useEffect(() => window.scrollTo(0, 0));
  
  return (
    <>
      <section className='Seccion_white_contenedor'>
            <div className='heading'>
              <h3>Curso de IBER Avanzado</h3>
              <h1>Curso de Avanzado de Modelización Numérica Bidimensional con Iber: Más allá de la hidrodinámica</h1>
              <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>17 de octubre 2023</h1>

            </div>
      </section>
      <section >

          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Horario del curso:</h1>

          <div className='container'>        
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Duración curso de iniciación: <b>3.5 horas</b>
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Hora de inicio: <b>12:30</b> (comida de 14:00 a 15:00 de forma conjunta con el profesorado)
                  </p>
                  
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Hora de fin: <b>17:00</b>
                  </p>
          </div> 

          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Aula del curso:</h1>

          <div className='container'>        
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    <b>Aula N1.2</b> situada en la 1ª planta del Aulario B parte trasera de Edificio ETSI de Caminos, canales y Puertos y de Ingeniería de Minas situado en Paseo Alfonso XIII 52, 30203 Cartagena, Murcia (<b><a href="/assets/docs/Ubicacion Aulas Cursos Precongresos.pdf" target="_blank" rel="noreferrer">Pinchar para ver ubicación</a></b>)
                  </p>                  
          </div> 

          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Profesores responsables del curso:</h1>

          <div className='container'>        
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                          Luis Cea Gómez (UDC) <a style={{textDecoration:'underline'}} href='mailto:luis.cea@udc.es'>luis.cea@udc.es</a>
                      </li>
                      
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                        Ernest Bladé Castellet (UPC) <a style={{textDecoration:'underline'}} href='mailto:ernest.blade@upc.edu'>ernest.blade@upc.edu</a>
                      </li>
              </ul>
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Marcos Sanz Ramos (UPC) <a style={{textDecoration:'underline'}} href='mailto:marcos.sanz-ramos@upc.edu'>marcos.sanz-ramos@upc.edu</a> 
                      </li>
              </ul>  
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Esteban Sañudo Costoya (UDC) <a style={{textDecoration:'underline'}} href='mailto:e.sanudo@udc.es'>e.sanudo@udc.es</a> 
                      </li>
              </ul>  
              <ul className='ul_autor'>
                      <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      Gonzalo García Alén (UDC) <a style={{textDecoration:'underline'}} href='mailto:g.glores@udc.es'>g.glores@udc.es</a> 
                      </li>
              </ul>  
          </div> 
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Contenido del curso:</h1> 
          <div className='container'> 
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Unidad 1. Iber, más allá de la hidrodinámica (30’)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Evolución de la herramienta numérica y módulos de cálculo adicionales.
                  </p>
              </div>
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Unidad 2. Transporte de sedimentos (90’)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Teoría y conceptos básicos para la modelización del transporte de sedimentos. Ejemplo de encauzamiento de un río de alta pendiente. Concepto de “capa de roca”, evolución del lecho y formas de fondo.
                  </p>
              </div>
              <div style={{marginBottom:'20px'}}>       
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.2rem'}}>
                    Unidad 3. Procesos hidrológicos (90’)
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Introducción al módulo de cálculo de Iber orientado a los procesos hidrológicos a nivel de cuenca, con especial énfasis en la generación y propagación de la escorrentía superficial. Teoría de la modelización hidrológica distribuida y caso de aplicación.
                  </p>
              </div>
          </div> 

          <div className='container' style={{marginBottom:'20px'}}> 
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    El curso de iniciación dará paso al curso avanzado. Ambos cursos se realizan uno a continuación del otro en el mismo aula. 
                </p> 
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  Cada persona matriculada deberá traer su propio portátil de trabajo con SO Windows.
                </p>  
              
                            
          </div> 
          <h1 style={{margin:'20px 0px 5px 30px', color:'#1886e6'}}>Organización:</h1> 
          <div className='container' style={{marginBottom:'20px'}}> 
        
            <div className="grid_img_cursos">
              <img src='/assets/footer/colaboradores/RLHE.jpg' alt='' width='200px'/>                         
          </div>

          
            <p style={{textAlign:'justify', fontSize:'1rem', marginBottom:'20px'}}>
            Curso organizado por la Red de Laboratorios de Hidráulica de España (RLHE)
                
            </p>
          </div>

          
              
        
         
          
      </section>
      

    </>
  )
}

export default Curso_IBER_Avanzado
