import React from "react"
import { Link } from "react-router-dom"


const Presentaciones = () => {
    // Recuperar el ancho de la pantalla
var widthpantalla = window.innerWidth;

// Recuperar el alto de la pantalla
var height = window.innerHeight;

  return (
    <>
      <section >
          <h3 style={{fontSize:'30px', fontWeight:'500', textTransform:'uppercase', color:'#1886e6', alignContent:'left', alignItems:'left', margin:'20px'}}>C)	Presentaciones</h3>
          <h1 style={{margin:'20px 0px 20px 30px', color:'#1886e6'}}>SESIONES ORALES</h1>

          <div className='container'>
            <div style={{margin:'0px 0px 0px 0px'}}>
                <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.5rem'}}>
                ANTES DEL INICIO DE LA SESIÓN
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  La/el ponente deberá cargar su presentación en el espacio habilitado para tal fin con la suficiente antelación. Adicionalmente debe incluir dos o tres líneas que recojan su afiliación y resuman su trayectoria profesional en el perfil de su cuenta de la página web <a style={{textDecoration:'underline'}} href='https://www.jia2023.upct.es/'>https://www.jia2023.upct.es/</a> antes del 18 de Octubre.
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El formato de las presentaciones deberá ser el de Microsoft Office PowerPoint o formato PDF. La presentación deberá incluir el logo de las JIA 2023 (descargable abajo).
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  La/el ponente debe estar en la sala asignada 5 minutos antes del inicio de su sesión, informando al moderador de su presencia.
                </p>

                <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.5rem', marginTop:'20PX'}}>
                DESARROLLO DE LA SESIÓN
                </p>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El tiempo completo del cual se dispone para cada ponente es de 15 minutos, repartidos como sigue:
                </p>
                
                <ul className='ul_autor'>
                   <li style={{textAlign: 'left', fontSize:'1rem'}}>
                   <b>12 minutos</b> para la presentación de la ponencia
                   </li>
                </ul>
                <ul className='ul_autor'>
                   <li style={{textAlign: 'left', fontSize:'1rem'}}>
                   <b>3 minutos</b> para el turno de preguntas
                   </li>
                </ul>
                <p style={{textAlign:'justify', fontSize:'1rem'}}>
                  El control de tiempos es fundamental para el correcto desarrollo de las sesiones, por lo que el control de tiempos será estricto.
                </p>
         
            </div>          
          </div>  


          <h1 style={{margin:'20px 0px 20px 30px', color:'#1886e6'}}>SESIONES PÓSTER</h1>
            <div className='container'>
              <div style={{margin:'0px 0px 0px 0px'}}>
                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.5rem'}}>
                  ANTES DEL INICIO DE LA SESIÓN
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    IMPRESIÓN POR PARTE DEL AUTOR: La/el ponente entregará su póster en el momento de la inscripción y recogida de información de acuerdo con el formato establecido.
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    IMPRESIÓN POR PARTE DE LA ORGANIZACIÓN: La/el ponente tiene la posibilidad de encargar la impresión del póster a la Organización de las JIA. El póster será impreso en papel de 80g/m2 y el coste del servicio es de 20€/póster. Para optar por este servicio, el autor deberá enviar el póster en formato PDF al correo facilitado por la organización <a style={{textDecoration:'underline'}} href='mailto:congreso.jia2023@upct.es'>(congreso.jia2023@upct.es)</a> con una antelación mínima de 5 días al comienzo de las JIA, indicando expresamente la petición del servicio.
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Las dimensiones del póster son las del formato DIN A0 con orientación vertical (118,9 cm de altura x 84,1 cm de ancho). El póster debe estar en un soporte flexible (p.ej. papel) y debe incluir el logo de las JIA 2023 (descargable abajo). Por favor, asegúrese de que su póster es legible a una distancia mínima de 1 metro.
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    El póster será entregado durante el registro y desde la organización lo colocaremos en su ubicación para las jornadas.
                  </p>


                  <p style={{textAlign:'justify', fontWeight:'500', fontSize:'1.5rem', marginTop:'20PX'}}>
                  DESARROLLO DE LA SESIÓN
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    El tiempo completo del cual se dispone para cada ponente es de 5 minutos, repartidos como sigue:
                  </p>
                  <ul className='ul_autor'>
                    <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      <b>3 minutos</b> de presentación
                    </li>
                  </ul>
                  <ul className='ul_autor'>
                    <li style={{textAlign: 'left', fontSize:'1rem'}}>
                      <b>2 minutos</b> para el turno de preguntas
                    </li>
                  </ul>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Las presentaciones se realizarán de pie, y se utilizarán los pósteres como soporte gráfico de las presentaciones. Las presentaciones se realizarán en un bloque de 5 sesiones paralelas de 12 presentaciones de 5 minutos cada una para las líneas A, B, C y D y en un bloque de 2 sesiones paralelas de 7 presentaciones de 5 minutos para el Monográfico (18 de Octubre de 19:00 a 20:15).
                  </p>
                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Es primordial que se realice una presentación donde prime la capacidad de síntesis, existiendo la posibilidad de debatir los detalles del trabajo con los asistentes que manifiesten su interés tras el turno de presentaciones.
                  </p>
                   <p style={{textAlign:'justify', fontSize:'1rem'}}>
                      Las presentaciones deben incluir el logo de la jornada:
                  </p>
                  <img   style={{width:'250px' }}  src='assets/logojia2023.png' alt='' />


                  <p style={{textAlign:'justify', fontSize:'1rem'}}>
                    Si tienes cualquier duda o consulta adicional, no dudes en ponerte en contacto con nosotros en <a style={{textDecoration:'underline'}} href='mailto:congreso.jia2023@upct.es'>congreso.jia2023@upct.es</a>
                  </p>
                  
                  

              </div>          
            </div>  

          
              
        
         
          
      </section>
    </>
  )
}

export default Presentaciones
